import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import moodImage from '../assets/20160704-2004--DSC_2771.landscape.jpg';
import { Button } from '@material-ui/core';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	onDone(): void,
}

export default function OnboardingPrinciple(props: Props) {
	// const {  } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}>Focus Trainer</Typography>
						<Typography variant="h5" color="inherit" className={classes.heading}><LText en="Focus Timer &amp; Self-Reflection" de="Focus Timer mit Selbstreflexion"/></Typography>
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description} paddingBottom={100}>
					<Typography variant="body1" color="inherit" paragraph><LText
						en="Focus Trainer will help you to develop and train your focusing skills."
						de="Focus Trainer hilft Dir, Deine Konzentrationsfähigkeit zu trainieren und zu entwickeln."
					/></Typography>
					<Typography variant="body1" color="inherit"><strong><LText en="Focus timer" de="Focus Timer"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText
						en="Decide for how long you want to focus and start the timer. Focus on your task until the time runs out. Resist all distractions. Reset the timer if you let yourself get distracted."
						de="Stelle den Timer auf die Zeitdauer, für die Du Dich konzentrieren möchtest. Konzentriere Dich auf Deine Aufgabe, bis der Timer abgelaufen ist. Setze den Timer zurück, falls Du Dich doch ablenken lässt."
					/></Typography>
					<Typography variant="body1" color="inherit"><strong><LText en="Improving focus step by step" de="Konzentration Schritt für Schritt verbessern"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText
						en="Just like with other skills, start small and work yourself up. Focus Trainer will gradually increase the length of your focus sessions. Easily grow your attention span from that of a goldfish to that of a zen monk."
						de="Klein anfangen und langsam steigern - dieses Prinzip gilt auch für die Konzentrationsfähigkeit. Focus Trainer wird schrittweise die Dauer Deiner Konzentrationsperioden verlängern. So kannst du deine Aufmerksamkeitsspanne leicht von der eines Goldfisches auf die eines Zen-Mönchs steigern."
					/></Typography>
					<Typography variant="body1" color="inherit"><strong><LText en="Distraction dumping" de="Ablenkungen ablegen"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText
						en="Instead of giving in to a distraction, braindump it directly in the app. Write it down. Get back to your task before you lose focus. Follow up on the distraction after your focus session (or not)."
						de="Anstatt einer Ablenkung nachzugeben oder viel Willenskraft aufzuwenden, um ihr zu widerstehen, lege sie einfach ab. Schreibe sie auf und kümmere Dich später darum. Das kannst Du direkt in Focus Trainer machen, dann bekommst Du Deine Notizen sofort nach der Konzentrations&shy;phase aufgreifen (oder nicht)."
					/></Typography>
					<Typography variant="body1" color="inherit"><strong><LText en="Self-reflection" de="Selbstreflexion"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText 
						en="Did you complete your focus session? Great! Not? Learn from it! Quickly note what caused you to break concentration."
						de="Hast Du Deine Konzentrationsphase erfolgreich abgeschlossen? Prima! Nicht? Dann lerne daraus! Notiere kurz, was Dich abgelenkt hat."
					/></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText
						en="Every once in a while, take a look at your collected reflections in the app. Focus Trainer will show you the most common reasons for interruptions. Tackle those first."
						de="Schau Dir von Zeit zu Zeit Deine gesammelten Reflexionen an. Focus Trainer zeigt Dir die häufigsten Unterbrechungen. Nimm diese als Erstes in Angriff."
					/></Typography>
					{/* <Button onClick={props.onDone} variant="contained" color="secondary" style={{width: "100%", color: "white", marginTop: 16}}>Get started</Button> */}
				</Box>
			</Container>
		</React.Fragment>
	);
}
