import React from 'react';

export interface FocusSession {
	id: string,
	date: Date,
	target: number,
	length: number,
	success: boolean,
	failedReason: string,
}

export interface FocusSessions {
	ready(): boolean,
	create(date: Date, target: number, length: number, success: boolean, failedReason?: string): FocusSession,
	sessions: FocusSession[],
}

const FocusSessionsContext = React.createContext<FocusSessions>({
	ready: () => false,
	create: (date: Date, target: number, length: number, success: boolean, failedReason?: string) => {
		throw new Error("FocusSessionProvider required");
	},
	sessions: [],
});

export default FocusSessionsContext;
