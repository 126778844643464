import React, { useEffect, useState } from 'react';
import { Storage } from '@capacitor/storage';

import OnboardingPrinciple from '../components/OnboardingPrinciple';
import OnboardingNotifications from '../components/OnboardingNotifications';
import NavigationOnboarding from '../components/NavigationOnboarding';
import { Box } from '@material-ui/core';
import { requestPermissions } from '../services/notifications-helpers';
import LoadingView from '../components/LoadingView';

export enum OnboardingStep { 
	principle = "principle",
	notifications = "notifications",
}

interface OnboardingViewControllerProps {
	onDone(): void,
}

export default function OnboardingViewController(props: OnboardingViewControllerProps) {
	const [loading, setLoading] = useState(true);
	const [currentStep, setCurrentStep] = useState(0);
	const [nextAvailable, setNextAvailable] = useState(false);
	const [notificationChosen, setNotificationChosen] = useState(false);
	const [awaitingNotificationPermission, setAwaitingNotificationPermission] = useState(false);
	// const { 
	// 	setNotificationsEnabled,
	//  } = useContext(SettingsContext);

	useEffect(() => {
		Storage.get({key: "OnboardingDone"}).then(value => {
			if (value.value)
				props.onDone();
			else
				setLoading(false);
		});
	}, []);

	const handleDone = () => {
		Storage.set({key: "OnboardingDone", value: (new Date().toJSON())});
		props.onDone();
	}

	const doEnableNotifications = () => {
		setAwaitingNotificationPermission(true);
		setNotificationChosen(true);
		requestPermissions();
		// checkNotificationsPermission();
		// setNotificationsEnabled(true);
		// logEvent('notifications_enable');
	}

	const doPostponeNotifications = () => {
		setNotificationChosen(true);
		// setNotificationsEnabled(false);
		handleDone();
		// logEvent('notifications_disable');
		// if (stepSequence.indexOf(step) === (stepSequence.length - 1)) {
		// 	handleFinish();
		// } else {
		// 	setStep(stepSequence[stepSequence.indexOf(step)+1]);
		// }
	}

	if (loading)
		return <LoadingView/>
	else
		return <>
				<Box paddingBottom={12}>
					{currentStep === 0 &&
						<OnboardingPrinciple onDone={() => setCurrentStep(currentStep+1)} />
					}
					{currentStep === 1 &&
						<OnboardingNotifications
							awaitingNotificationPermission={awaitingNotificationPermission}
							doEnableNotifications={doEnableNotifications}
							doPostponeNotifications={doPostponeNotifications}
						/>
					}
				</Box>
				<NavigationOnboarding
					steps={2}
					stepIndex={currentStep}
					nextAvailable={currentStep === 0 || awaitingNotificationPermission}
					onNext={() => currentStep === 1 ? handleDone() : setCurrentStep(currentStep + 1)}
					onBack={() => setCurrentStep(currentStep-1)}
				/>
		</>;
}
