import React from 'react';

export interface RemoteConfig {
	ready(): boolean,
	helpShowPhone: boolean,
}

const RemoteConfigContext = React.createContext<RemoteConfig>({
	ready: () => false,
	helpShowPhone: true,
});

export default RemoteConfigContext;
