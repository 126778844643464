import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import DialogNotched from './DialogNotched';
// import ImageHeader from './ImageHeader';
// import moodImage from '../assets/saketh-garuda-5ngpvlyzlZU-unsplash.1920.jpg';
import celebrationImage from '../assets/undraw_celebration_0jvk.svg';
// import moodImage from '../assets/david-gavi-Ijx8OxvKrgM-unsplash.landscape3.jpg';
// import moodImage from '../assets/jean-wimmerlin-eB6D0Wks7jA-unsplash.1920.jpg';
import { Box, Link } from '@material-ui/core';
import { Braindump } from '../contexts/BraindumpsContext';
import BraindumpCheckbox from './BraindumpCheckbox';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		// backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, #8c0032, #c2185b)`, // red
		// backgroundImage: `linear-gradient(135deg, #193e1b, #388e3c)`, // Green
		// backgroundImage: `linear-gradient(135deg, #29434e, #546e7a)`, // bluegray
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		// width: "100%",
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	whatNow: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	titleRoot: {
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: theme.spacing(2) * -1,
	},
	card: {
		marginTop: theme.spacing(3),
	},
}));


export interface SessionSuccessDialogProps {
	onCancel(): void,
	onSave(): void,
	// onBreak(): void,
	// onFocus(): void,
	braindumpValue: string,
	braindumps?: Braindump[],
	streakWidget?: JSX.Element,
	onChangeBraindump?(item: Braindump): void,
	onDeleteBraindump?(itemId: string): void,
}

export default function SessionSuccessDialog(props: SessionSuccessDialogProps) {

	// const suggestedReasons = Array(200).fill("").map(() => String(Math.round(Math.random() * 20000)));
	const theme = useTheme();
	// const { formatMessage, locale } = useIntl();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={props.onCancel}
			aria-labelledby="responsive-dialog-title"
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogTitle id="responsive-dialog-title" classes={{root:classes.titleRoot}} disableTypography={true}>
				{/* <ImageHeader
					image={moodImage}
					title="A setback?"
					subtitle={"Mark objective as missed: "+objectiveTitle}
				/> */}
				{/* <ImageHeader
					image={moodImage}
					title="Well done!"
					// subtitle="On noes"
				/> */}
				{/* <FormattedMessage id="FailedObjectiveDialog.title" defaultMessage="Missed: {objectiveTitle}" values={{objectiveTitle: objectiveTitle}}/> */}
				<Box className={classes.headerBackground} padding={2}>
					<Typography variant="h4" color="inherit" ><LText en="Well done!" de="Gut gemacht"/> 🥳</Typography>
				</Box>
			</DialogTitle>
			<DialogContent style={{padding: 0}}>

				<Box padding={2} paddingTop={0}>
					<Typography variant="h6" gutterBottom><LText en="You held your focus. <strong>Celebrate!</strong>" de="Du bist konzentriert geblieben. <strong>Feiere das!</strong>"/></Typography>
					<img alt="Celebration!" src={celebrationImage} style={{maxWidth: "250px"}}/>
					{/* <IllustrationCelebration/> */}
					<Typography variant="body1" gutterBottom paragraph><LText en="Seriously, do celebrate right now." de="Ernsthaft, feiere das jetzt sofort."/> <Link href="https://ideas.ted.com/how-you-can-use-the-power-of-celebration-to-make-new-habits-stick/" target="_blank" rel="noopener"><LText en="Learn why." de="Erfahre warum."/></Link></Typography>
				</Box>

				<Box style={{backgroundColor:"#f3f3f3"}} padding={2}>
					<Typography variant="h6"><LText en="Focus rate" de="Konzentrationsquote"/></Typography>
					{props.streakWidget}
				</Box>

				{props.braindumps && props.braindumps.length > 0 &&
					<Box padding={2} marginTop={2}>
						<Typography variant="h6"><LText en="Braindumped" de="Abgelegt"/></Typography>
						<Typography variant="body1" gutterBottom paragraph><LText en="Here are your notes to follow up on. You can do this now or get back to them later." de="Hier sind Deine Notizen. Du kannst Dich sofort darum kümmern oder es später tun."/></Typography>

						{/* <Box style={{backgroundColor:"#f4f4f4"}} padding={1}> */}
							{props.braindumps?.map((d, idx) => 
								<BraindumpCheckbox
									key={d.id}
									braindump={d}
									onChangeContent={(content: string) => props.onChangeBraindump?.(Object.assign({}, d, {content: content}))}
									onChangeDone={(done: boolean) => props.onChangeBraindump?.(Object.assign({}, d, {done: done}))}
									onDelete={() => props.onDeleteBraindump?.(d.id)}
								/>
							)}
						{/* </Box> */}
						{/* <BraindumpItem time="19.04. 15:29" text={props.braindumpValue}/> */}
						{/* <TextField
							// label={<>
							// 	Braindump
							// 	<ButtonDialog>Yo</ButtonDialog>
							// 	</>}
							// label="Braindump"
							helperText="Just delete everything that you've taken care of."
							// placeholder="Idea? "
							
							multiline 
							fullWidth
							// autoFocus
							value={props.braindumpValue}
							// rowsMax={4}
						/> */}
					</Box>
				}

			</DialogContent>
			<DialogActions>
				{/* <Button onClick={props.onCancel} data-analytics-id="SessionSuccessDialog-cancel">
					Cancel
				</Button> */}
				<Button onClick={props.onSave} color="secondary" data-analytics-id="SessionSuccessDialog-save">
					<LText en="Continue" de="Weiter"/>
				</Button>
				{/* <Button onClick={props.onFocus} color="secondary" data-analytics-id="SessionSuccessDialog-next">
					New session
				</Button>
				<Button onClick={props.onBreak} color="primary" data-analytics-id="SessionSuccessDialog-break">
					Take a break
				</Button> */}
			</DialogActions>
		</DialogNotched>
	);
}
