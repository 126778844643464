import React, { ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Avatar, Box, Chip, Tab, Tabs, TextField, Theme } from '@material-ui/core';
import Timer from './Timer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BraindumpItem from './BraindumpItem';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DialpadIcon from '@material-ui/icons/Dialpad';
import TimeChip from './TimeChip';
import { FocusMode } from '../App';
import LText from '../tools/LText';

interface TimerOption {
	minutes: number,
	locked: boolean,
}

const useStyles = makeStyles<Theme, TimerViewV2Props>(theme => ({
	textfield: {
		"& div": {
			paddingBottom: theme.spacing(2),
		},
		"& div::before": {
			borderBottom: "none",
		}
	},
	numberChip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
		// fontSize: 16,
		// color: props => props.mode === FocusMode.Focus ? theme.palette.text.secondary : theme.palette.text.primary,
	},
	numberChipWhiteText: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
		// color: props => props.mode === FocusMode.Focus ? "white" : theme.palette.text.primary,
		color: "white",
		// fontSize: 16,
	},
	numberChipAvatar: {
		// fontSize: "16px !important",
		// height: "40px !important",
		// width: "40px !important",
	},
	numberChipLock: {
		fontSize: 17,
		marginLeft: theme.spacing(1),
		color: "grey",
	},
}));

export interface TimerViewV2Props {
	progress: number,
	braindumping: boolean,
	braindumpValue?: string,
	// time: string,
	minutes: number,
	seconds: number,
	running: boolean,
	disableUnderline: boolean,
	editing: boolean,
	mode?: FocusMode,
	append?: ReactNode,
	hideDumps?: boolean,
	onStart(): void,
	onSetTime(minutes:number): void,
	onSetCustomTime(): void,
	focusTimes: TimerOption[],
	onFocusModeChange(mode: FocusMode): void,
}



export default function TimerViewV2(props: TimerViewV2Props) {
	const classes = useStyles(props);
	const [focusMode, setFocusMode] = useState(props.mode || FocusMode.Focus);

	const [tabState, setTabState] = React.useState(0);

	const handleTabChange = (event:unknown, newValue:FocusMode) => {
	//   setTabState(newValue);
		setFocusMode(newValue);
		props.onFocusModeChange(newValue);
	};

	const dumpsOpen = [
		{time: "15.4. 21:47", text: "- Call back Jeff\n- Check out news article"},
		{time: "15.4. 21:08", text: "- Read up on Curevac\n- Plan rest of the day\n- Empty trash"},
		// {time: "15.4. 20:23", text: "- Check in on Alex"},
		// {time: "15.4. 21:47", text: "- Call back Jeff\n- Check out news article"},
		// {time: "15.4. 21:08", text: "- Read up on Curevac\n- Plan rest of the day\n- Empty trash"},
		// {time: "15.4. 20:23", text: "- Check in on Alex"},
		// {time: "15.4. 21:47", text: "- Call back Jeff\n- Check out news article"},
		// {time: "15.4. 21:08", text: "- Read up on Curevac\n- Plan rest of the day\n- Empty trash"},
		// {time: "15.4. 20:23", text: "- Check in on Alex"},
		// {time: "15.4. 21:47", text: "- Call back Jeff\n- Check out news article"},
		// {time: "15.4. 21:08", text: "- Read up on Curevac\n- Plan rest of the day\n- Empty trash"},
		// {time: "15.4. 20:23", text: "- Check in on Alex"},
	];

	const dumpsDone = [

	];

	// const focusTimes = [
	// 	{minutes: 5, locked: false},
	// 	{minutes: 10, locked: false},
	// 	{minutes: 15, locked: false},
	// 	{minutes: 25, locked: true},
	// 	{minutes: 45, locked: true},
	// 	// {minutes: 60, locked: true},
	// ];

	const breakTimes = [
		{minutes: 5, locked: false},
		{minutes: 10, locked: false},
		{minutes: 15, locked: false},
	];

	const times = focusMode === FocusMode.Focus ? props.focusTimes : breakTimes;
	// logger.debug("Times:", times, props);
	
	return <>

	<Box paddingBottom={10}>
		<Grid container>
			<Grid item xs={12} 
			// md={6}
			>
				<Box textAlign="center" padding={2}>
					<Box display="inline-block" marginBottom={5} data-joyride-id="timerviewv2-tabs">
						<Tabs
							value={focusMode}
							indicatorColor={focusMode === FocusMode.Focus ? "secondary" : "primary"}
							textColor="primary"
							onChange={handleTabChange}
							// aria-label="disabled tabs example"
						>
							<Tab label={<LText en="Focus" de="Fokus"/>} value={FocusMode.Focus} />
							{/* <Tab label="Disabled" disabled /> */}
							<Tab label={<LText en="Relax" de="Pause"/>} value={FocusMode.Relax}/>
						</Tabs>
					</Box><br/>

					<div data-joyride-id="timerviewv2-timer">
					<Timer
						progress={props.progress}
						minutes={props.minutes}
						seconds={props.seconds}
						running={props.running}
						color={focusMode === FocusMode.Focus ? "secondary" : "primary"}
						onStart={props.onStart}
						
						/>
					</div>
					<br/>

					<Box marginTop={5} maxWidth={300} display="inline-block" data-joyride-id="timerviewv2-times">
						{times.map(v =>
							<TimeChip key={v.minutes} minutes={v.minutes} mode={focusMode} locked={v.locked} onClick={() => props.onSetTime(v.minutes)}/>
						)}
						<br/>
						<Chip 
							label={<LText en="custom" de="andere"/>}
							// avatar={<Avatar className={classes.numberChipAvatar}><EditIcon style={{fontSize:16}}/></Avatar>}
							avatar={<Avatar className={classes.numberChipAvatar}><DialpadIcon style={{fontSize:16}}/></Avatar>}
							// avatar={<Avatar className={classes.numberChipAvatar}>_</Avatar>}
							className={classes.numberChip}
							clickable
							color={focusMode === FocusMode.Focus ? "default" : "primary"}
							onClick={props.onSetCustomTime}
						/>
					</Box>
				</Box>
			</Grid>
			{!props.hideDumps && 
				<Grid item xs={12}
					// md={6}
					>
					<Box textAlign="center" padding={2}>
				
						<Box marginTop={3} maxWidth={300} width="100%" display="inline-block" textAlign="center">
							<Typography variant="h5" gutterBottom>Braindumps</Typography>
							{dumpsOpen.length === 0 &&
								<Box textAlign="center" marginTop={3} marginBottom={3}>
									<DoneAllIcon fontSize="large"/>
									<Typography variant="body1">All done!</Typography>
								</Box>
							}
							{dumpsOpen.length > 0 && dumpsOpen.map((dump, index) =>
								<BraindumpItem key={index} time={dump.time} text={dump.text}/>
								// <Paper style={{marginBottom:16, padding: 8, paddingBottom: 0, backgroundColor:"#f4f4f4", position: "relative"}} elevation={0}>
								// 	<Checkbox style={{position:"absolute", right: 0, top: 0}}/>
								// 	<TextField
								// 		// label={<>
								// 		// 	Braindump
								// 		// 	<ButtonDialog>Yo</ButtonDialog>
								// 		// 	</>}
								// 		label={dump.time}
								// 		// helperText="Idea? Unexpected to-do? Write it down, bother later."
								// 		// placeholder="Idea? "
								// 		multiline 
								// 		fullWidth
								// 		// autoFocus
								// 		value={dump.text}
								// 		// rowsMax={4}
								// 		// style={{marginBottom:24}}
								// 		className={classes.textfield}
								// 	/>
								// </Paper>
							)}

							<Button startIcon={<ChevronRightIcon/>}>Show archived</Button>
							{/* <Link style={{textDecoration: "underline", cursor: "pointer"}}><ChevronRightIcon/> Show archived</Link> */}
							

						</Box>
					</Box>
				</Grid>
			}
		</Grid>
			{props.braindumping &&
				<Box marginTop={10}>
					<TextField
					// label={<>
					// 	Braindump
					// 	<ButtonDialog>Yo</ButtonDialog>
					// 	</>}
					label="Braindump"
					helperText="Idea? Unexpected to-do? Write it down, bother later."
					// placeholder="Idea? "

					multiline 
					fullWidth
					autoFocus
					value={props.braindumpValue}
					// rowsMax={4}
				/>
				</Box>
			}
		</Box>
			{props.append}
	</>
	;
}

