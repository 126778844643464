import React, { useContext, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
// import { FormattedMessage, useIntl } from 'react-intl';
import logger from '../services/logger';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogNotched from './DialogNotched';
// import ImageHeader from './ImageHeader';
// import moodImage from '../assets/aaron-thomas-wsi9Te1yNwA-unsplash.1920.jpg';
// import moodImage from '../assets/jean-wimmerlin-eB6D0Wks7jA-unsplash.1920.jpg';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { Braindump } from '../contexts/BraindumpsContext';
import BraindumpCheckbox from './BraindumpCheckbox';
import LangContext from '../contexts/LangContext';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		// backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		// backgroundImage: `linear-gradient(135deg, #8c0032, #c2185b)`, // red
		backgroundImage: `linear-gradient(135deg, #29434e, #546e7a)`, // bluegray
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		// width: "100%",
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	headingGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	periodControls: {
		textAlign: "right"
	},
	back: {
		textAlign: "left"
	},
	forward: {
		textAlign: "right"
	},
	title: {
		textAlign: "center"
	},
	periodChooser: {
		textAlign: "center",
	},
	periodChooserButton: {
		height: "40px",
	},
	unscheduled: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		textAlign: "center",
		height: "48px",
		boxSizing: "content-box",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	whatNow: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	recurringNotice: {
		marginTop: theme.spacing(4),
	},
	titleRoot: {
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: theme.spacing(2) * -1,
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	whatNowTitle: {
		marginTop: theme.spacing(4),
	},
}));


export interface SessionFailedDialogProps {
	onSave(): void,
	onCancel(): void,
	// onBreak(): void,
	// onFocus(): void,
	onChangeFailedReason(reason: string): void,
	suggestedReasons: string[],
	initialFailedReason: string,
	braindumpValue: string,
	braindumps?: Braindump[],
	streakWidget?: JSX.Element,
	onChangeBraindump?(item: Braindump): void,
	onDeleteBraindump?(itemId: string): void,
}

export default function SessionFailedDialog(props: SessionFailedDialogProps) {
	const { 
		onCancel,
		onChangeFailedReason,
		suggestedReasons,
		initialFailedReason,
	} = props;
	// const suggestedReasons = Array(200).fill("").map(() => String(Math.round(Math.random() * 20000)));
	const theme = useTheme();
	// const { formatMessage, locale } = useIntl();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [failedReason, setFailedReason] = useState(initialFailedReason);
	const [showCustomReason, setShowCustomReason] = useState(false);

	const handleReasonClick = (reason: string) => {
		if (reason === failedReason) {
			setFailedReason("");
			onChangeFailedReason("");
		} else {
			setFailedReason(reason);
			onChangeFailedReason(reason);
		}
		setShowCustomReason(false);
	}

	const handleOtherReasonClick = () => {
		setShowCustomReason(!showCustomReason);
		setFailedReason("");
		onChangeFailedReason("");
	}

	// logger.debug("Initial failed reason:", initialFailedReason, failedReason);

	const lang = useContext(LangContext);

	const reasons = lang.lang === "de" ? 
		[
			"Anruf",
			"E-Mail",
			"Social Media",
			"Kollegen",
		]
		:
		[
			"Phone call",
			"Email",
			"Social media",
			"Co-worker dropped by",
		]
	;

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={onCancel}
			aria-labelledby="responsive-dialog-title"
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogTitle id="responsive-dialog-title" classes={{root:classes.titleRoot}} disableTypography={true}>
				{/* <ImageHeader
					image={moodImage}
					title="A setback?"
					subtitle={"Mark objective as missed: "+objectiveTitle}
				/> */}
			<Box className={classes.headerBackground} padding={2}>
					{/* <Box padding={2}> */}
						<Typography variant="h4" color="inherit" ><LText en="Focus session aborted 😔" de="Sitzung unterbrochen 😔"/></Typography>
					{/* </Box> */}
			</Box>
				{/* <ImageHeader
					image={moodImage}
					title="Focus session aborted"
					// subtitle="On noes"
				/> */}
				{/* <FormattedMessage id="FailedObjectiveDialog.title" defaultMessage="Missed: {objectiveTitle}" values={{objectiveTitle: objectiveTitle}}/> */}
			</DialogTitle>
			<DialogContent style={{padding: 0}}>

				<Box padding={2} paddingTop={0}>

					<Typography variant="h6" gutterBottom paragraph><LText en="What happened?" de="Was ist passiert?"/></Typography>

					{reasons.map(reason => 
						<Chip key={reason} label={reason} className={classes.chip} color={reason === failedReason ? "primary" : undefined} onClick={() => handleReasonClick(reason)}/>
					)}
					<Chip label={<LText en="Other" de="Anderes"/>} className={classes.chip} color={showCustomReason ? "primary" : undefined} onClick={handleOtherReasonClick}/>

					{showCustomReason && 
						<Box marginTop={1}>
							<Autocomplete
								freeSolo
								disableClearable
								options={suggestedReasons}
								inputValue={failedReason}
								onInputChange={(e, value) => {
									logger.debug("Reason changed", e, value, typeof value);
									if (e) { // Hack: Only change value if e is set. Otherwise it resets value to empty on initialization.
										setFailedReason(value);
										onChangeFailedReason(value);
									}
								}}
								renderInput={(params) => (
									<TextField
										label={<LText en="What happened?" de="Was ist passiert?"/>}
										// @ts-ignore
										fullWidth
										autoFocus
										{...params}
										// value={failedReason}
										// onChange={(e) => {
										// 	const value = e.target.value;
										// 	logger.debug("Reason changed (textfield)", value);
										// 	setFailedReason(value);
										// 	onChangeFailedReason(value);
										// }}
									/>
								)}
							/>
						</Box>
					}
				</Box>
				
				<Box style={{backgroundColor:"#f3f3f3"}} padding={2} marginTop={2}>
					<Typography variant="h6"><LText en="Focus rate" de="Konzentrationsquote"/></Typography>
					{props.streakWidget}
				</Box>

				<Box padding={2} marginTop={2}>
					<Typography variant="h6"><LText en="Braindumped" de="Abgelegt"/></Typography>
					<Typography variant="body1" gutterBottom paragraph><LText en="Here are your notes to follow up on. You can do this now or get back to them later." de="Hier sind Deine Notizen. Du kannst Dich sofort darum kümmern oder es später tun."/></Typography>
					{props.braindumps?.map((d, idx) => <>
						<BraindumpCheckbox
							key={d.id}
							braindump={d}
							onChangeContent={(content: string) => props.onChangeBraindump?.(Object.assign({}, d, {content: content}))}
							onChangeDone={(done: boolean) => props.onChangeBraindump?.(Object.assign({}, d, {done: done}))}
							onDelete={() => props.onDeleteBraindump?.(d.id)}
							/>
					</>)}
				</Box>

			</DialogContent>
			<DialogActions>
				{/* <Button onClick={props.onFocus} color="secondary" data-analytics-id="SessionSuccessDialog-next">
					New session
				</Button>
				<Button onClick={props.onBreak} color="primary" data-analytics-id="SessionSuccessDialog-break">
					Take a break
				</Button> */}
				{/* <Button onClick={props.onCancel} data-analytics-id="SessionFailedDialog-cancel">
					Cancel
				</Button> */}
				<Button onClick={props.onSave} color="secondary" data-analytics-id="SessionFailedDialog-save">
					<LText en="Continue" de="Weiter"/>
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
