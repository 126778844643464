import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import florian from '../assets/florian-avatar.120.jpg';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(8),
	},
	question: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	skip: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4),
		textAlign: "right",
	},
	avatar: {
		width: "120px",
		height: "120px",
		marginRight: theme.spacing(2),
		float: "left",

	}
}));

const FormattedMessage = (props: {id: string, defaultMessage: string}) => {
	return <>{props.defaultMessage}</>;
}

export default function HelpView(props: {
	showPhoneNumber: boolean,
}) {
	const classes = useStyles();
	const locale = "en";

	return (
		<React.Fragment>
			<Container maxWidth="xs" className={classes.container}>
				<Box className={classes.description}>
					<Typography variant="h4" color="inherit" className={classes.heading}><LText en="Questions? Ideas?" de="Fragen? Wünsche?"/></Typography>
					<Typography variant="h6" color="inherit" className={classes.heading} style={{marginBottom: 32}}><LText en="If you need help, experience issues or have any suggestions, please don't hesitate to contact me!" de="Falls etwas klemmt, unklar ist, oder Du sonstige Anregungen hast: Zögere nicht, mich zu kontaktieren!"/></Typography>
					{/* <Typography variant="h6" gutterBottom color="inherit">Help to improve Focality by telling us your thoughts.</Typography> */}
					<Avatar className={classes.avatar} src={florian}/>
						<Typography variant="body1">
							<LText en="Help me build the best product for you!" de="Hilf mir, das beste Produkt für Dich zu entwickeln!"/>
						</Typography>
						<Typography variant="caption">
							― <LText en="Florian, developer of Focus Trainer" de="Florian, Entwickler Focus Trainer"/>
						</Typography>
					<div style={{clear: "both", marginBottom: "0px"}}></div>
					{/* <Typography variant="body1" color="inherit">You are one of the first users, so it would be extremely helpful to hear your first impressions first hand.</Typography> */}

					{/* <ul style={{paddingLeft: 20}}>
						<li><FormattedMessage id="onboarding.interview.bullet1" defaultMessage="Tell me a bit about your expectations."/></li>
						<li><FormattedMessage id="onboarding.interview.bullet2" defaultMessage="Learn how to get the most out of Focality."/></li>
						<li><FormattedMessage id="onboarding.interview.bullet3" defaultMessage="Get access to all features for 6 months for free: Unlimited goals, unlimited insights."/></li>
					</ul> */}

					<Button variant="contained" color="secondary" style={{ marginTop: 32, marginBottom: 10}} href="mailto:info@focustrainer.app" data-analytics-id="OnboardingInterview-schedule"><LText en="Send EMail" de="E-Mail senden"/></Button>
					{props.showPhoneNumber && <>
						&nbsp;&nbsp;&nbsp;<Button variant="contained" color="secondary" style={{ marginTop: 32, marginBottom: 10}} href="tel:+4976145875709" data-analytics-id="help-call"><LText en="Phone call" de="Anrufen"/></Button>
					</>}
					{/* {props.showPhoneNumber && <Typography variant="body1"><FormattedMessage id="onboarding.interview.call" defaultMessage="Or try calling me directly:"/><br/> <Link href="tel:+4976145875709" data-analytics-id="OnboardingInterview-phone">+49 (0) 761 / 458 757 09</Link></Typography>} */}
				</Box>
				<Box className={classes.question}>
					<Typography variant="body1">Website: <Link href="https://www.focustrainer.app" data-analytics-id="help-website" target="_blank" rel="noopener">www.focustrainer.app</Link></Typography>
					<Typography variant="body1"><LText en="Email" de="E-Mail"/>: <Link href="mailto:info@focustrainer.app" data-analytics-id="help-email">info@focustrainer.app</Link></Typography>
					{props.showPhoneNumber && <Typography variant="body1"><LText en="Phone" de="Telefon"/>: <Link href="tel:+4976145875709" data-analytics-id="OnboardingInterview-phone">+49 (0) 761 / 458 757 09</Link></Typography>}

					{/* <Typography variant="body2" color="inherit" gutterBottom><FormattedMessage id="onboarding.interview.explanation" defaultMessage="Why? I want to experience and understand your first impressions. This way I'll know where to improve the onboarding process and what to focus on so that Focality meets and exceeds your expectations."/></Typography> */}
				</Box>
				{/* <Box className={classes.skip}>
					<Button variant="contained" data-analytics-id="OnboardingInterview-skip"><FormattedMessage id="onboarding.interview.skip" defaultMessage="Skip"/><KeyboardArrowRight /></Button>
				</Box> */}
			</Container>
		</React.Fragment>
	);
}
