import React, { useContext } from 'react';
import BraindumpsView from '../components/BraindumpsView';
import BraindumpsContext, { Braindump } from '../contexts/BraindumpsContext';


interface BraindumpsViewControllerProps {
}

export default function BraindumpsViewController(props: BraindumpsViewControllerProps) {

	const braindumps = useContext(BraindumpsContext);

	return <>
		<BraindumpsView
			isEmpty={(braindumps.items.length === 0)}
			// isEmpty={false}
			braindumps={braindumps.items}
			onChangeBraindump={(item: Braindump) => braindumps.changeItem(item)}
			onDeleteBraindump={(id: string) => braindumps.deleteItem(id)}
		/>
	</>;
}
