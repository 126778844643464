import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Input from '@material-ui/core/Input';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LText from '../tools/LText';


const useStyles = makeStyles(theme => ({
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		// width: "100vw",
		width: "100%",
		height: "100vh",
		transition: "height 2s",
	}
}));

export interface TimerViewProps {
	progress: number,
	braindumping: boolean,
	braindumpValue?: string,
	// time: string,
	minutes: number,
	seconds: number,
	running: boolean,
	disableUnderline: boolean,
	editing: boolean,
	onSucceed(): void,
	onFail(): void,
	onCancel(): void,
	showDone: boolean,
	showInterrupt: boolean,
	showCancel?: boolean,
	targetReached: boolean,
	cancelCountdown: number,
	color: "primary" | "secondary",
	append?: ReactNode,
	braindumper?: ReactNode,
}

export default function TimerView(props: TimerViewProps) {
	const classes = useStyles();
	
	return <>

		<Box className={classes.container} >

				{/* <Box> */}
				<Box position="relative" display="inline-flex" data-joyride-id="timerview-timer">
					<CircularProgress
						variant="determinate"
						value={100 + props.progress}
						size={300}
						color={props.color}
						thickness={5}
					/>
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						paddingTop={1}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Box>
							{/* <Box textAlign="center"><Typography variant="h2">{props.time}</Typography></Box>  */}
							<Box textAlign="center">
								<Typography variant="h2" data-joyride-id="timerview-time">
								{props.editing ? <>
									<Input
										style={{
											fontSize: "3.75rem",
											fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
											fontWeight: 300,
											lineHeight: 1.2,
											letterSpacing: '-0.00833em',
											width: 100,
											textAlign: "right",
										}}
									value="25"
									type="number"
									margin="none"
									disableUnderline={props.disableUnderline}
									/>
									m
								</> : props.running ? <>
									{props.targetReached && "+"}{props.minutes}:{props.seconds.toString().padStart(2, "0")}
								</> : <>
								{props.minutes}m
								</>}
								</Typography>
							</Box> 
							{/* <Box textAlign="center" marginTop={1}><Button startIcon={<NotesIcon/>} color="secondary">Braindump</Button></Box>
							<Box textAlign="center"><Button>Abort</Button></Box> */}
							{props.running && 
								<Box textAlign="center" marginTop={2}>
									{props.showCancel && props.cancelCountdown > 0 &&
										<Button variant="outlined" onClick={props.onCancel} startIcon={<ArrowBackIosIcon fontSize="large"/>}><LText en="Cancel" de="Abbrechen"/> ({props.cancelCountdown})</Button>
									}
									{props.showDone &&
										// <IconButton onClick={props.onSucceed} data-joyride-id="timerview-done"><DoneOutlinedIcon fontSize="large"/></IconButton>
										<Button onClick={props.onSucceed} variant="outlined" color={props.color} startIcon={<DoneOutlinedIcon fontSize="large"/>}><LText en="Done" de="Fertig"/></Button>
									}
									{props.showInterrupt &&
										// <IconButton onClick={props.onFail} data-joyride-id="timerview-fail"><ClearOutlinedIcon fontSize="large"/></IconButton>
										<Button variant="outlined" onClick={props.onFail} startIcon={<ClearOutlinedIcon fontSize="large"/>} data-joyride-id="timerview-fail"><LText en="Interrupt" de="Abgelenkt"/></Button>
									}
									{/* <IconButton color={props.braindumping ? "secondary" : "primary"}><NotesIcon/></IconButton> */}
									{/* <IconButton onClick={props.onSucceed} data-joyride-id="timerview-done"><DoneOutlinedIcon fontSize="large"/></IconButton>
									<IconButton onClick={props.onFail} data-joyride-id="timerview-fail"><ClearOutlinedIcon fontSize="large"/></IconButton> */}
								</Box>
							}
							{!props.running && 
								<Box textAlign="center">
									{/* <Button variant="outlined" startIcon={<PlayArrowIcon fontSize="large"/>}>Start</Button> */}
									<IconButton><PlayArrowIcon 
									fontSize="large"
									/></IconButton>
								</Box>
							}
						</Box>
					</Box>
				</Box>
				{/* </Box> */}

							{/* <Box textAlign="center" marginTop={2}><Button startIcon={<NotesIcon/>} variant="outlined" color="secondary">Braindump</Button></Box>
							<Box textAlign="center"><Button>Abort</Button></Box> */}

			{props.braindumper && <>
				<Box marginTop={10} paddingBottom={2} data-joyride-id="timerview-braindump">
					{props.braindumper}
					
					{/* <TextField
					// label={<>
					// 	Braindump
					// 	<ButtonDialog>Yo</ButtonDialog>
					// 	</>}
					label="Braindump"
					helperText="Idea? Unexpected to-do? Write it down, bother later."
					// placeholder="Idea? "

					multiline 
					fullWidth
					autoFocus
					value={props.braindumpValue}
					// rowsMax={4}
					/> */}

				</Box>
			</>}
		</Box>
		{props.append}
	</>
	;
}

