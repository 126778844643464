import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, IconButton, TextField, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Braindump } from '../contexts/BraindumpsContext';

const useStyles = makeStyles(theme => ({
	root: {
		display: "block",
	},
	label: {
		whiteSpace: "pre-wrap",
		display: "inline-flex",
	},
	textfield: {
		marginTop: theme.spacing(1),
		"& div": {
			// paddingBottom: theme.spacing(2),
		},
		"& div::before": {
			borderBottom: "none",
		},
	},
}));

interface BraindumpCheckboxProps {
	braindump: Braindump,
	onChangeDone?(checked: boolean): void,
	onChangeContent?(content: string): void,
	onDelete?(): void,
}

export default function BraindumpCheckbox(props: BraindumpCheckboxProps) {
	const classes = useStyles();
	const [editing, setEditing] = useState(false);
	const [value, setValue] = useState(props.braindump.content);

	const handleBlur = () => {
		props.onChangeContent?.(value);
		setEditing(false);
	}

	return <>
		{/* <Box display="flex">
			<Checkbox style={{marginLeft: -12}} onChange={(event, checked) => {if (props.onChange) props.onChange(checked)}}/>
			<Typography style={{marginTop: 8, flexGrow: 1}}>I{props.value}</Typography>
			<IconButton><ArchiveIcon/></IconButton>
		</Box> */}
		<Box display="flex" alignItems="flex-start">
			<Checkbox
				style={{marginLeft: -12, paddingTop: 12, paddingBottom: 0}}
				checked={props.braindump.done}
				onChange={(event, checked) => {if (props.onChangeDone) props.onChangeDone(checked)}}
			/>
			{editing ? <>
				<TextField
						// label={<>
						// 	Braindump
						// 	<ButtonDialog>Yo</ButtonDialog>
						// 	</>}
						// label={props.time}
						// helperText="Idea? Unexpected to-do? Write it down, bother later."
						// placeholder="Idea? "
						multiline 
						fullWidth
						autoFocus
						value={value}
						// rowsMax={4}
						// style={{marginBottom:24}}
						className={classes.textfield}
						onChange={(e) => setValue(e.target.value)}
						onBlur={handleBlur}
					/>
			</>	: <>
				<Typography style={{marginTop: 14, flexGrow: 1, cursor: "pointer", whiteSpace: "pre-wrap", lineHeight: "19px"}} onClick={() => setEditing(true)}>{props.braindump.content}</Typography>			</>}
			<IconButton style={{opacity: editing ? 1 : 0, paddingTop: 16, paddingBottom: 0}} onClick={props.onDelete}><DeleteForeverIcon/></IconButton>
		</Box>
		{/* <FormControlLabel
				className={classes.root}
				classes={{label: classes.label}}
				control={<Checkbox onChange={(event, checked) => {if (props.onChange) props.onChange(checked)}}/>}
				label={props.value}
				// label={<>fslkjsldkfjsklfd<br/>jsksdkfjsdkfj</>}
			/> */}
	</>;

	// return (
	// 	<>
	// 		<FormControlLabel
	// 			className={classes.root}
	// 			classes={{label: classes.label}}
	// 			control={<Checkbox onChange={(event, checked) => {if (props.onChangeDone) props.onChangeDone(checked)}}/>}
	// 			label={props.value}
	// 			// label={<>fslkjsldkfjsklfd<br/>jsksdkfjsdkfj</>}
	// 		/>
	// 	</>
	// );
}
