import React, { useContext, useEffect, useRef, useState } from 'react';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { FocusMode } from '../App';
import SessionFailedDialog from '../components/SessionFailedDialog';
import SessionSuccessDialog from '../components/SessionSuccessDialog';
import TimerView from '../components/TimerView';
import FocusSessionsContext from '../contexts/FocusSessionsContext';
import logger from '../services/logger';
import Braindumper from '../components/Braindumper';
import BraindumpsContext, { Braindump } from '../contexts/BraindumpsContext';
import { getStreakWidget } from './InsightsViewController';
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { App } from '@capacitor/app';
import { useLString } from '../tools/LText';

const alarmSound = require('../assets/mixkit-data-scaner-2847.mp3').default;

interface TimerViewControllerProps {
	targetSeconds: number,
	mode: FocusMode,
	onCancel(): void,
	onSucceeded(elapsed: number): void,
	onInterrupted(elapsed: number): void,
	onChangeBraindumpIds(ids: string[]): void,
	braindumpIds: string[],
	startDate?: Date,
}

enum State {
	running,
	celebration,
	interruption,
}

export default function TimerViewController(props: TimerViewControllerProps) {

	const [elapsed, setElapsed] = useState(0);
	const [state, setState] = useState(State.running);
	const started = useRef(props.startDate || new Date());
	const [cancelCountdown, setCancelCountdown] = useState(props.mode === FocusMode.Relax ? 0 : 3);
	const [failedReason, setFailedReason] = useState("");
	const [alarm, setAlarm] = useState(false);
	const appActive = useRef(true);
	const testOffset = useRef(0);
	const lstr = useLString();

	const focusSessions = useContext(FocusSessionsContext);
	const braindumps = useContext(BraindumpsContext);

	const cancelNotification = () => {
		logger.debug("Cancelling notification...");
		LocalNotifications.cancel({notifications: [{id: 1}]}).then(() => {
			LocalNotifications.getPending().then(notifications => logger.debug('Pending notifications after cancel: ', notifications));
		});
	}

	useEffect(() => {
		const notificationAt = addSeconds(started.current, props.targetSeconds);
		logger.debug("Scheduling notification for "+notificationAt+". Timer started at: "+started.current);
		const notification: LocalNotificationSchema = {
			title: lstr("Session complete!", "Sitzung abgeschlossen!"),
			body: lstr(
				"You successfully completed your {isFocus, select, true {focus session} other {break session}}.",
				"You successfully completed your {isFocus, select, true {focus session} other {break session}}.",
				{isFocus: props.mode === FocusMode.Focus}
			),
			id: 1,
			schedule: { at: notificationAt },
			// sound: null,
			// attachments: null,
			actionTypeId: "COMPLETE",
		};
		
		LocalNotifications.schedule({notifications: [notification]}).then(() => {
			LocalNotifications.getPending().then(notifications => logger.debug('Pending notifications after init: ', notifications));
		});

		App.addListener("appStateChange", state => {
			logger.info('appStateChange: ', state);
			appActive.current = state.isActive;
		});

	}, [lstr, props.mode, props.targetSeconds]);

	useEffect(() => {
		if (state === State.running) {
			logger.debug("Starting timer interval");
			const interval = setInterval(() => {
				const now = new Date();
				const diff = differenceInSeconds(now, started.current);
				setElapsed(diff + testOffset.current);
				setCancelCountdown(old => old - 1);
			}, 1000);
			return () => {
				logger.debug("Clearing interval");
				clearInterval(interval);
			};
		} else {
			logger.debug("NOT starting timer interval. State: "+state);
		}
	}, [state]);

	useEffect(() => {
		if (alarm) {
			logger.info("Sounding alarm...");
			const player = new Audio(alarmSound);
			player.play();
		}
	}, [alarm]);
	
	const remaining = props.targetSeconds - elapsed;
	const remainingAbs = Math.abs(remaining);
	
	const minutes = Math.floor(remainingAbs / 60);
	const seconds = remainingAbs % 60;
	// const minutes = 7;
	const progress = remaining > 0 ? 100 - (remaining / props.targetSeconds) * 100: 0;
	
	const colorRemaining = props.mode === FocusMode.Focus ? "secondary" : "primary";
	const colorDone = props.mode === FocusMode.Focus ? "primary" : "secondary";
	const color = remaining > 0 ? colorRemaining : colorDone; // #388e3c
	const showDone = (props.mode === FocusMode.Relax) || (remaining <= 0);
	if (remaining < 1 && remaining > -1 && !alarm)
		setAlarm(true);

	if (remaining === 3 && appActive.current) {
		cancelNotification();
	}

	const handleSessionSuccess = () => {
		logger.info("Session succeeded. Elapsed time: " + elapsed);
		cancelNotification();
		if (props.mode === FocusMode.Relax) {
			// Relaxed mode is just done. Signal success, nothing more.
			// saveBraindumps();
			props.onSucceeded(elapsed)
		} else {
			// Focus mode: Log session and show celebration screen.
			focusSessions.create(new Date(), props.targetSeconds, elapsed, true);
			setState(State.celebration);
		}
	}

	const handleSessionFail = () => {
		logger.info("Session failed. Elapsed time: " + elapsed);
		cancelNotification();
		setState(State.interruption);
	}

	const handleSessionFailDone = () => {
		logger.info("Saving failed session. Reason: "+failedReason);
		focusSessions.create(new Date(), props.targetSeconds, elapsed, false, failedReason);
		// saveBraindumps();
		props.onInterrupted(elapsed);
	}

	// const saveBraindumps = () => {
	// 	braindumpItems.forEach(item => braindumps.create(item.date, item.content, item.done));
	// }

	const braindumpItems = braindumps.items.filter(i => props.braindumpIds.includes(i.id));
	// logger.debug("Braindumps", braindumpIds, braindumpItems, braindumps.items);
	const braindumper = <Braindumper
		// onChange={items => setBraindumpItems(items)}
		braindumps={braindumpItems}
		onCreateBraindump={(content: string) => {
			const item = braindumps.create(new Date(), content, false);
			const newIds = [...props.braindumpIds, item.id];
			props.onChangeBraindumpIds(newIds);
			if (content === "test complete") {
				// Simulate completed timer (used for user testing)
				testOffset.current = props.targetSeconds - elapsed;
			}
		}}
		onChangeBraindump={(item: Braindump) => braindumps.changeItem(item)}
		onDeleteBraindump={(id: string) => {
			const newIds = props.braindumpIds.filter(i => i !== id);
			props.onChangeBraindumpIds(newIds);
			braindumps.deleteItem(id);
		}}
	/>

	const showCancel = !showDone && cancelCountdown > 0;

	const handleCancel = () => {
		cancelNotification();
		props.onCancel();
	}

	return <>
		<TimerView
			progress={progress}
			braindumping={true}
			running={true}
			minutes={minutes}
			seconds={seconds}
			disableUnderline={true}
			editing={false}
			onSucceed={handleSessionSuccess}
			onFail={handleSessionFail}
			onCancel={handleCancel}
			targetReached={remaining < 0}
			cancelCountdown={cancelCountdown}
			color={color}
			showDone={showDone}
			showInterrupt={!showDone && !showCancel}
			showCancel={showCancel}
			braindumper={braindumper}
		/>
		{state === State.celebration && <SessionSuccessDialog
			braindumpValue={"- Call back Jeff\n- Check out news article"}
			braindumps={braindumpItems}
			streakWidget={getStreakWidget(focusSessions.sessions)}
			onCancel={() => {}}
			onSave={() => {props.onSucceeded(elapsed)}}
			onChangeBraindump={(item: Braindump) => braindumps.changeItem(item)}
			onDeleteBraindump={(itemId: string) => braindumps.deleteItem(itemId)}
			// onChangeBraindump={(date: Date, content: string, done: boolean) => braindumps.changeItem(date, content, done)}
			// onDeleteBraindump={(date: Date) => braindumps.deleteItem(date)}
		/>}
		{state === State.interruption && <SessionFailedDialog
			braindumpValue={"- Call back Jeff\n- Check out news article"}
			initialFailedReason=""
			suggestedReasons={[]}
			braindumps={braindumpItems}
			streakWidget={getStreakWidget(focusSessions.sessions)}
			onCancel={() => {}}
			onSave={handleSessionFailDone}
			onChangeFailedReason={(reason: string) => setFailedReason(reason)}
			onChangeBraindump={(item: Braindump) => braindumps.changeItem(item)}
			onDeleteBraindump={(itemId: string) => braindumps.deleteItem(itemId)}
		/>}
	</>;
}
