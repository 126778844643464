import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import FocusSessionsProvider from './contexts/FocusSessionsProvider';
import BraindumpsProvider from './contexts/BraindumpsProvider';
import LangProvider from './contexts/LangProvider';
import RemoteConfigProvider from './contexts/RemoteConfigProvider';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN === "null" ? undefined : process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <RemoteConfigProvider>
      <LangProvider>
        <FocusSessionsProvider>
          <BraindumpsProvider>
            <App />
          </BraindumpsProvider>
        </FocusSessionsProvider>
      </LangProvider>
    </RemoteConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
