import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { Avatar, Link, Button, Chip, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FocusMode } from '../App';
import DialogNotched from './DialogNotched';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	numberChip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
		// fontSize: 16,
		// color: props => props.mode === FocusMode.Focus ? theme.palette.text.secondary : theme.palette.text.primary,
	},
	numberChipLock: {
		fontSize: 17,
		marginLeft: theme.spacing(1),
		color: "grey",
	},
}));

export interface TimeChipProps {
	minutes: number,
	locked: boolean,
	mode: FocusMode,
	onClick(): void,
}

export default function TimeChip(props: TimeChipProps) {
	const classes = useStyles();
	const [showLockedConfirmation, setShowLockedConfirmation] = useState(false);

	const handleClose = () => setShowLockedConfirmation(false);
	const handleSelect = () => {
		if (props.locked) {
			setShowLockedConfirmation(true);
		} else {
			props.onClick();
		}
	}
	
	return <>
		<Chip 
			key={props.minutes}
			label={<>min{props.locked && <LockIcon className={classes.numberChipLock}/>}</>}
			avatar={<Avatar>{props.minutes}</Avatar>}
			className={classes.numberChip}
			// disabled={v.locked}
			clickable
			color={props.locked ? undefined : props.mode === FocusMode.Focus ? "secondary" : "primary"}
			onClick={handleSelect}
		/>
		{showLockedConfirmation &&
		<DialogNotched
			open={true}
			onClose={handleClose}
		>
			<DialogTitle>
				<LockIcon style={{position: "relative", top: 2}}/> <LText en="Session length locked" de="Zeit gesperrt"/>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1" paragraph><LText
					en="This target time is still locked. Train with shorter targets to unlock it."
					de="Diese Zielzeit ist noch gesperrt. Übe mit kürzeren Zielen um sie freizuschalten."
				/></Typography>
				<Typography variant="body1" paragraph><LText
					en="Remember: You don't need to stop focusing when the timer runs out. But to set longer times as a target from the beginning, you need to master the shorter times first."
					de="Denke daran: Du musst nicht aufhören, Dich zu konzentrieren, wenn der Timer abläuft. Aber um sich von Anfang an längere Zeiten als Ziel zu setzen, musst Du zuerst die kürzeren Zeiten beherrschen."
				/></Typography>
				<Typography variant="body1"><Link href="#" target="_blank" rel="noopener"><LText en="Learn why." de="Mehr erfahren."/></Link></Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					<LText en="Close" de="Schließen"/>
				</Button>
				{/* <Button onClick={props.onClick} color="primary">
					Proceed
				</Button> */}
			</DialogActions>
		</DialogNotched>
		}
	</>
	;
}

