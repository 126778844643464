import React, { useState, useEffect } from 'react';
import logger from '../services/logger';
import FocusSessionsContext, { FocusSession } from './FocusSessionsContext';
import { Storage } from '@capacitor/storage';
import BraindumpsContext, { Braindump } from './BraindumpsContext';
import { isEqual, parseJSON } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

interface Props {
	children: React.ReactNode,
}
export default function BraindumpsProvider(props: Props) {

	const [ready, setReady] = useState<boolean>(true);
	const [items, setItems] = useState<Braindump[]>([]);

	useEffect(() => {
		Storage.get({ key: 'Braindumps' }).then(value => {
			logger.debug("Braindump data:"+value.value);
			if (value.value)
				setItems(JSON.parse(value.value).map((item:any) => {
					const newItem: Braindump = {
						id: item.id,
						date: parseJSON(item.date),
						content: item.content,
						done: item.done,
					};
					return newItem;
				}));
		});
		
		Storage.keys().then(value => logger.debug('Storage keys', value));
	}, []);


	const contextValue = {
		ready: () => ready,
		create: (date: Date, content: string, done: boolean) => {
			const newItem = {
				id: uuidv4(),
				date: date,
				content: content,
				done: done,
			};
			setItems(old => {
				const updatedItems = [...old, newItem];
				logger.debug("Updating Braindump storage");
				Storage.set({key: "Braindumps", value: JSON.stringify(updatedItems)});
				return updatedItems;
			});
			return newItem;
		},
		items: items,
		changeItem: (item: Braindump) => {
			setItems(old => {
				const updatedItems = old.map(i => i.id !== item.id ? i : Object.assign({}, i, {
					date: item.date,
					content: item.content,
					done: item.done,
					}));
				Storage.set({key: "Braindumps", value: JSON.stringify(updatedItems)});
				return updatedItems;
			});
		},
		deleteItem: (id: string) => setItems(old => {
			const updatedItems = old.filter(i => i.id !== id);
			Storage.set({key: "Braindumps", value: JSON.stringify(updatedItems)});
			return updatedItems;
		}),
	}

	logger.debug("Updated BraindumpsProvider", items);

	return (
		<BraindumpsContext.Provider value={contextValue}>
			{props.children}
		</BraindumpsContext.Provider>
	);
}
