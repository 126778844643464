import React from 'react';

export interface Braindump {
	id: string,
	date: Date,
	content: string,
	done: boolean,
}

export interface Braindumps {
	ready(): boolean,
	create(date: Date, content: string, done: boolean): Braindump,
	items: Braindump[],
	changeItem(item: Braindump): void,
	deleteItem(id: string): void,
}



const BraindumpsContext = React.createContext<Braindumps>({
	ready: () => false,
	create: (date: Date, content: string, done: boolean) => ({id: "", date: new Date(), content: "", done: false}),
	items: [],
	changeItem: (item: Braindump) => {},
	deleteItem: (id: string) => {},
});

export default BraindumpsContext;
