import React from 'react';
import { useTheme } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Joyride, { CallBackProps, EVENTS, STATUS } from 'react-joyride';
import logger from '../services/logger';
import { JoyrideProps } from '../controllers/JoyrideController';
import LText from '../tools/LText';

export default function TimerViewV2Joyride(props: JoyrideProps) {
	const theme = useTheme();

	const steps = [
		{
			target: '*[data-joyride-id="timerviewv2-tabs"]',
			content: <LText
				en="You can switch between focus sessions and breaks.{br}{br}Breaks don't count towards your stats."
				de="Du kannst zwischen Konzentrationsphasen und Pausen wechseln.{br}{br}Pausen haben keinen Einfluss auf Deine Statistiken."
			/>,
			disableBeacon: true,
		},
		{
			target: '*[data-joyride-id="timerviewv2-timer"]',
			content: <LText
				en="Click/tap on {icon} to start your focus session. Once started, don't loose your focus until the timer runs out."
				de="Klicke/tippe auf {icon}, um die Sitzung zu starten. Sobald sie begonnen hat, solltest Du sie auch ohne Ablenkung zu Ende bringen."
				values={{icon: <PlayArrowIcon/>}}
			/>,
			disableBeacon: true,
			// placement: "top",
		},
		{
			target: '*[data-joyride-id="timerviewv2-times"]',
			content: <LText
				en="Use these presets to quickly change the target length of your session.{br}{br} You'll need to master the shorter targets before you can set longer times."
				de="Nutze diese Vorlagen, um schnell die Dauer Deiner Konzentrationsphasen zu ändern.{br}{br} Du musst zunächst die kürzeren Dauern sicher beherrschen, bevor Du längere Dauern einstellen kannst."
			/>,
			disableBeacon: true,
		},
	];

	const onStateChange = (event: CallBackProps) => {
		// logger.debug("PlanViewOnboarding, state changed: ", event);
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.SKIPPED && event.index === 0) {
			logger.info("Tour skipped");
			// logEvent("tour_skipped");
		}
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.SKIPPED && event.index > 0) {
			logger.info("Tour aborted");
			// logEvent("tour_aborted");
		}
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.FINISHED) {
			logger.info("Tour completed");
			// logEvent("tour_completed");
		}
		if (event.type === EVENTS.BEACON) {
			logger.info("Tour paused");
			// logEvent("tour_paused");
		}
		if (event.type === EVENTS.TOUR_END) {
			logger.info('TimerViewV2Joyride: Tour ended');
			props.onDone?.();
		}
	}
	
	return <>
		<Joyride
			steps={steps}
			// stepIndex={0}
			continuous={true}
			callback={onStateChange}
			disableOverlay={true}
			showSkipButton={true}
			locale={{
				back: <LText en="Back" de="Zurück"/>,
				// close: 'Close',
				// last: <FormattedMessage id="PlanViewOnboading.done" defaultMessage="Done"/>,
				last: <LText en="Done" de="Fertig"/>,
				next: <LText en="Next" de="Weiter"/>,
				skip: <LText en="Skip" de="Überspringen"/>,
			}}
			styles={{
				options: {
					primaryColor: theme.palette.secondary.main,
				}
			}}
		/>
	</>
	;
}

