import React from 'react';
import { useTheme } from '@material-ui/core';
import Joyride, { CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import logger from '../services/logger';
import { JoyrideProps } from '../controllers/JoyrideController';
import LText from '../tools/LText';

export default function TimerRunningJoyride(props: JoyrideProps) {
	const theme = useTheme();

	const steps: Step[] = [
		{
			target: '*[data-joyride-id="timerview-timer"]',
			content: <LText
				en="Your focus timer is now running. Do not let your attention wander until the time is up!"
				de="Der Timer läuft. Lass Dich nicht ablenken, bis er abgelaufen ist!"
			/>,
			disableBeacon: true,
		},
		// {
		// 	target: '*[data-joyride-id="timerview-done"]',
		// 	content: <>Once you've successfully completed your session, end it by clicking on <DoneOutlinedIcon/></>,
		// 	disableBeacon: true,
		// 	offset: 5,
		// },
		// {
		// 	target: '*[data-joyride-id="timerview-fail"]',
		// 	content: <>If you do let yourself get distracted, reset the timer by clicking on <ClearOutlinedIcon/>.</>,
		// 	disableBeacon: true,
		// 	offset: 0,
		// },
		{
			target: '*[data-joyride-id="timerview-braindump"]',
			content: <LText
				en="If something nags at your attention, quickly write it down. Get it onto the screen and out of your head. You can check up on it later."
				de="Falls etwas an Deiner Aufmerksamkeit zerrt, schreib es auf. Verlagere es aus Deinem Kopf auf den Bildschirm. Du kannst Dich später darum kümmern."
			/>,
			disableBeacon: true,
			// placement: "top",
		},
		{
			target: '*[data-joyride-id="timerview-fail"]',
			content: <LText
				en="If you do let yourself get distracted before the time runs out, stop the timer."
				de="Falls Du Dich doch ablenken lässt, stoppe den Timer."
			/>,
			disableBeacon: true,
			offset: 0,
		},

	];

	const onStateChange = (event: CallBackProps) => {
		// logger.debug("PlanViewOnboarding, state changed: ", event);
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.SKIPPED && event.index === 0) {
			logger.info("Tour skipped");
			// logEvent("tour_skipped");
		}
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.SKIPPED && event.index > 0) {
			logger.info("Tour aborted");
			// logEvent("tour_aborted");
		}
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.FINISHED) {
			logger.info("Tour completed");
			// logEvent("tour_completed");
		}
		if (event.type === EVENTS.BEACON) {
			logger.info("Tour paused");
			// logEvent("tour_paused");
		}
		if (event.type === EVENTS.TOUR_END) {
			logger.info('TimerRunningJoyride: Tour ended');
			props.onDone?.();
		}
	}
	
	return <>
		<Joyride
			steps={steps}
			// stepIndex={0}
			continuous={true}
			callback={onStateChange}
			disableOverlay={true}
			showSkipButton={true}
			locale={{
				back: <LText en="Back" de="Zurück"/>,
				// close: 'Close',
				// last: <FormattedMessage id="PlanViewOnboading.done" defaultMessage="Done"/>,
				last: <LText en="Done" de="Fertig"/>,
				next: <LText en="Next" de="Weiter"/>,
				skip: <LText en="Skip" de="Überspringen"/>,
			}}
			styles={{
				options: {
					primaryColor: theme.palette.secondary.main,
				}
			}}
		/>
	</>
	;
}

