import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	fullScreen: {
		paddingTop: `env(safe-area-inset-top)`,
		paddingBottom: `env(safe-area-inset-bottom)`,
		paddingLeft: `env(safe-area-inset-left)`,
		paddingRight: `env(safe-area-inset-right)`,
		background: (props: DialogProps & DialogNotchedProps) => props.backgroundImage ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.backgroundImage})` : undefined,
		backgroundSize: (props: DialogProps & DialogNotchedProps) => props.backgroundImage ? "cover" : undefined,
	},
	default: {
		zIndex: 99999,
		background: (props: DialogProps & DialogNotchedProps) => props.backgroundImage ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.backgroundImage})` : undefined,
		backgroundSize: (props: DialogProps & DialogNotchedProps) => props.backgroundImage ? "cover" : undefined,
	},
}));

export interface DialogNotchedProps {
	backgroundImage?: string,
}

/**
 * Same as Dialog, but adds padding 
 * @param props 
 */
export default function DialogNotched(props: DialogProps & DialogNotchedProps) {
	const classes = useStyles(props);

	return (
		<Dialog 
			classes={
				props.fullScreen ? {paper: classes.fullScreen} : {paper: classes.default}
			}
			{...props}>

		</Dialog>
	);
}
