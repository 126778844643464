import React, { useContext } from 'react';
import FocusSessionsContext, { FocusSession } from '../contexts/FocusSessionsContext';
import InsightsView from '../components/InsightsView';
import DualStreak from '../components/DualStreak';
import { countBy } from 'lodash';
import LText from '../tools/LText';

export const getStreakWidget = (sessions: FocusSession[]) => {
	const lastSessions = sessions.slice(-10);
	const lastSuccessfulSessions = lastSessions.filter(fs => fs.success);
	const streaks = sessions.reduce((result, session) => 
		(session.success ? result[result.length-1]++ : result.push(0), result)
	, [0]);
	const longestStreak = Math.max(...streaks);
	const currentStreak = streaks.slice(-1)[0];

		
	const streak = <DualStreak
		numerator={lastSuccessfulSessions.length}
		denominator={lastSessions.length}
		streakCurrent={currentStreak}
		streakMax={longestStreak}
		title=""
		labelCurrent={<LText en="Current average" de="Aktueller Durchschnitt"/>}
		labelStreak={<LText en="Last {current} sessions successful. Record: {longest} sessions." de="Letzte {current} Sitzungen erfolgreich. Rekord: {longest} Sitzungen." values={{current: currentStreak, longest: longestStreak}}/>}
	/>
	return streak;
}

export interface FailedReasonCounted {
	reason: string,
	count: number,
}

export function getFailedReasonsCounted(sessions: FocusSession[]): FailedReasonCounted[] {
	const reasonsObj = countBy(sessions.map(s => s.failedReason).filter(r => r && r !== ""));
	const reasons = Object.keys(reasonsObj)
		.map(k => ({reason: k, count: reasonsObj[k]}))
		.sort((a, b) => {
			if (a.count < b.count)
				return 1;
			else if (a.count > b.count)
				return -1;
			else
				return 0;
		});
	return reasons;
}

interface InsightsViewControllerProps {
}

export default function InsightsViewController(props: InsightsViewControllerProps) {

	const focusSessions = useContext(FocusSessionsContext);
	const streakWidget = getStreakWidget(focusSessions.sessions);

	return <>
		<InsightsView
			// failedReasons={[
			// 	{reason: "Phone call", count: 24},
			// 	{reason: "Co-worker dropped by", count: 17},
			// 	{reason: "Sidetracked while researching", count: 11},
			// 	{reason: "Needed coffee", count: 7},
			// 	{reason: "Distracted by email", count: 4},
			// 	{reason: "Spotify safari", count: 4}
			// ]}
			failedReasons={getFailedReasonsCounted(focusSessions.sessions)}
			isEmpty={false}
			streakWidget={streakWidget}
		/>
	</>;
}
