import React from 'react';

export interface LangCtx {
	lang: string,
}

const LangContext = React.createContext<LangCtx>({
	lang: "en",
});

export default LangContext;
