import React, { useEffect, useState } from 'react';
import { Storage } from '@capacitor/storage';
import logger from '../services/logger';

export interface JoyrideProps {
	onDone?(): void,
}

interface JoyState {
	id: string,
	done: Date | null,
}

interface JoyrideControllerProps {
	ride: JSX.Element,
	id: string,
}

export default function JoyrideController(props: JoyrideControllerProps) {
	const [states, setStates] = useState<JoyState[]|null>(null);

	useEffect(() => {
		Storage.get({key: "Joyrides"}).then(value => {
			if (value.value) {
				const rideStates = JSON.parse(value.value);
				setStates(rideStates);
			} else {
				setStates([]);
			}
		});
	}, []);

	function handleDone() {
		logger.info("Joyride "+props.id+" done.");
		const item: JoyState = {
			id: props.id,
			done: new Date(),
		};
		setStates(old => {
			const newState = old ? [...old.filter(o => o.id !== props.id)] : [];
			newState.push(item);
			Storage.set({key: "Joyrides", value: JSON.stringify(newState)});
			return newState;
		});
	}

	const rideState = states && states.find(i => i.id === props.id);
	if (states === null || (rideState && rideState.done))
		return null;
	else 
		return <>
			{React.cloneElement(props.ride, {
				onDone: handleDone,
			})}
			{/* {props.ride} */}
		</>;
}
