import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NearMeIcon from '@material-ui/icons/NearMe';
// import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import BarChartIcon from '@material-ui/icons/BarChart';
// import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';
import { Box, IconButton, Paper } from '@material-ui/core';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LText from '../tools/LText';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
	bottomNavigation: {
		position: "fixed",
		bottom: 0,
		width: "100%",
		paddingBottom: "env(safe-area-inset-bottom)",
		zIndex: 99,
		// display: "flex",
	},
	bottomNavigationSelected: {
		color: theme.palette.secondary.main + ' !important',
	},
}));


interface NavigationBottomProps {
	selected: string,
	onNavigateTimer(): void,
	onNavigateBraindumps?(): void,
	onNavigateInsights(): void,
	onNavigateAccount(): void,
	onNavigateDebug?(): void,
	onNavigateHelp?(): void,
}

export default function NavigationBottom(props: NavigationBottomProps) {
	const { selected } = props;
	const [debugCounter, setDebugCounter] = useState(1);
	const classes = useStyles();

	const handlePeriodChange = (event:unknown, value:string) => {
		switch (value) {
			case "timer":
				props.onNavigateTimer();
				break;
			case "braindumps":
				props.onNavigateBraindumps?.();
				break;
			case "insights":
				props.onNavigateInsights();
				checkDebug();
				break;
			case "account":
				props.onNavigateAccount();
				break;
			case "help":
				props.onNavigateHelp?.();
				break;
			default:
				throw new Error("Unexpected navigation value "+value);
		}
	};

	const checkDebug = () => {
		if (selected !== "insights")
			return;
		if (debugCounter >= 10) {
			setDebugCounter(1);
			props.onNavigateDebug?.();
			// const debugInput = prompt();
			// if (debugInput)
			// 	subscription.doOverride(debugInput);
			// subscription.doRefresh();
		} else {
			setDebugCounter(debugCounter+1);
		}
	}

	return (
		<Paper elevation={2} className={classes.bottomNavigation}>
			<Box flexGrow={1}>
			<BottomNavigation
				value={selected}
				onChange={handlePeriodChange}
				>
				<BottomNavigationAction label="Timer" value="timer" icon={<NearMeIcon />} classes={{selected: classes.bottomNavigationSelected}} data-joyride-id="navigation-timer"/>
				<BottomNavigationAction label={<LText en="Braindumps" de="Ablage"/>} value="braindumps" icon={<ListIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-braindumps"/>
				<BottomNavigationAction label={<LText en="Insights" de="Auswertungen"/>} value="insights" icon={<BarChartIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-insights"/>
				<BottomNavigationAction label={<LText en="Help" de="Hilfe"/>} value="help" icon={<HelpOutlineIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-help"/>
				{/* <BottomNavigationAction label="Account" value="account" icon={<PersonIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-account"/> */}
			</BottomNavigation>
			</Box>
			{/* <Box position="absolute" right={0} bottom={0}>
				<IconButton><MoreVertIcon /></IconButton>
			</Box> */}
		</Paper>
	);
}
