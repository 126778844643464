import React, { ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Collapse, Container, FormControlLabel, Switch } from '@material-ui/core';
import { Braindump } from '../contexts/BraindumpsContext';
import BraindumpCheckbox from './BraindumpCheckbox';
import { compareDesc, format } from 'date-fns';
import logger from '../services/logger';
import emptyImageSVG from '../assets/undraw_empty_xct9.svg';
import celebrationImageSVG from '../assets/undraw_celebration_0jvk.svg';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(10),
	},
	listItemText: {
		margin: 0,
	},
	card: {
		marginTop: theme.spacing(3),
	},
}));

interface Group {
	date: string,
	dateFormatted: ReactNode,
	items: Braindump[],
};


export interface BraindumpsViewProps {
	isEmpty: boolean,
	braindumps: Braindump[],
	onChangeBraindump?(item: Braindump): void,
	onDeleteBraindump?(id: string): void,
}

export default function BraindumpsView(props: BraindumpsViewProps) {
	const [showDone, setShowDone] = useState(false);
	const classes = useStyles();

	// const open = props.braindumps.filter(d => !d.done);
	// const open = props.braindumps.filter(d => showDone || !d.done);
	const dumps = props.braindumps;
	// const open = props.braindumps;
	// const done = props.braindumps.filter(d => d.done);

	const openDates: Group[] = [];
	// const doneDates = [];

	dumps.sort((a, b) => compareDesc(a.date, b.date)).forEach(d => {
		logger.debug(d);
		const date = format(d.date, 'MM/dd/yyyy');
		const dateFormatted = <LText en="{date, date, ::yyyyMMdd}" de="{date, date, ::yyyyMMdd}" values={{date: d.date}}/>;
		let group = openDates.find(g => g.date === date);
		if (!group) {
			group = {
				date: date,
				dateFormatted: dateFormatted,
				items: [],
			};
			openDates.push(group);
		}
		group.items.push(d);
		// if (!openDates.includes(date))
		// 	openDates.push(date);
	});

	function groupHasVisibleItems(group: Group) {
		if (showDone)
			return true;
		else
			return (group.items.filter(i => !i.done).length > 0);
	}

	let anyItemVisible = false;
	if (showDone) {
		anyItemVisible = true;
	} else {
		for (let i = 0; i < openDates.length; i++) {
			if (openDates[i].items.find(d => !d.done)) {
				anyItemVisible = true;
				break;
			}
		}
	}

	return <>
		<Container maxWidth="sm" className={classes.container}>

			<Typography variant="h3"><LText en="Braindumps" de="Ablage"/></Typography>
			<Typography variant="subtitle1"><LText en="Your repository of formerly distracting thoughts." de="Deine abgelegten Gedanken."/></Typography>

			{props.isEmpty && <>
				<Box marginLeft="auto" marginRight="auto" width={300} marginTop={10} textAlign="center">
				{/* <IllustrationEmpty/> */}
					<img alt="" src={emptyImageSVG} style={{width: "100%"}}/>
				</Box>
				<Box textAlign="center" maxWidth={420} marginLeft="auto" marginRight="auto" marginTop={1}>
				{/* <Typography variant="h6">Nothing noted, yet</Typography> */}
				<Typography variant="body1" style={{textAlign:"center"}}><LText en="You did not capture anything so far." de="Du hast noch nichts notiert."/></Typography>
				</Box>
			</>}

			{!props.isEmpty && <>

				<Box 
					marginTop={4}
					// marginBottom={-6}
					// textAlign="right"
					>
					<FormControlLabel
						control={<Switch checked={showDone} onChange={() => setShowDone(!showDone)} />}
						label={<LText en="Show archived items" de="Archivierte Einträge anzeigen"/>}
						// labelPlacement="start"
					/>
				</Box>
				<Collapse in={!anyItemVisible}>
					<Box
						marginTop={4}
						maxWidth={350}
						textAlign="center"

						// textAlign="center" maxWidth={350} marginLeft="auto" marginRight="auto"
						// marginTop={-2}
						>
					<img alt="Celebration!" src={celebrationImageSVG} style={{width: "100%"}}/>
					<Typography variant="h6"><LText en="All done!" de="Alles erledigt!"/></Typography>
					</Box>
				</Collapse>
				{openDates.map(group =>
					<React.Fragment key={group.date}>
						<Collapse in={groupHasVisibleItems(group)}>
						<Typography variant="h6" style={{marginTop: 16}}>{group.dateFormatted}</Typography>
						{group.items.map((d, idx) => <React.Fragment key={d.id}>
							<Collapse in={showDone || !d.done}>
							<div>
								<BraindumpCheckbox
									key={idx}
									braindump={d}
									onChangeContent={(content: string) => props.onChangeBraindump?.(Object.assign({}, d, {content: content}))}
									onChangeDone={(done: boolean) => {
										props.onChangeBraindump?.(Object.assign({}, d, {done: done}))
										// d.done = !d.done;
									}}
									onDelete={() => props.onDeleteBraindump?.(d.id)}
								/>
							</div>
							</Collapse>
						</React.Fragment>)}
						</Collapse>
					</React.Fragment>
				)}
				{/* {props.braindumps.map(item => <>
					<BraindumpCheckbox value={item.content}/>
				</>)} */}
			</>}
		</Container>
	</>
	;
}

