import React, { useState, useContext, useEffect } from 'react';
import DebugView from '../components/DebugView';
import LangContext from '../contexts/LangContext';
import { Device } from '@capacitor/device';
import { getNotificationPermissionStatus } from '../services/notifications-helpers';


interface Props {
}

export default function DebugViewController(props: Props) {
	const [values, setValues] = useState({});
	const lang = useContext(LangContext);

	useEffect(() => {
		Device.getLanguageCode().then(languageCode => {
			setValues(old => Object.assign({}, old, {
				deviceLanguage: languageCode.value,
				focusTrainerLanguage: lang.lang,
			}));
		});
		Device.getInfo().then(r => {
			setValues(old => Object.assign({}, old, r));
		});
		getNotificationPermissionStatus().then(r => setValues(old => Object.assign({}, old, {
			notificationPermissionStatus: r ? "enabled" : "disabled",
		})))
	}, [lang.lang]);


	return (
		<React.Fragment>
			<DebugView
				values={values}
			/>
		</React.Fragment>
	);
}
