import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Card, CardContent, CardHeader, Container, List, ListItem, ListItemText } from '@material-ui/core';
import IllustrationEmpty from './../assets/IllustrationEmpty';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(10),
	},
	listItemText: {
		margin: 0,
	},
	card: {
		marginTop: theme.spacing(3),
	},
}));

export interface FailedReasonCounted {
	reason: string,
	count: number,
}

export interface InsightsViewProps {
	failedReasons: FailedReasonCounted[],
	isEmpty: boolean,
	streakWidget?: JSX.Element,
}

export default function InsightsView(props: InsightsViewProps) {
	const classes = useStyles();

	return <>
		<Container maxWidth="sm" className={classes.container}>

			<Typography variant="h3"><LText en="Focus Insights" de="Auswertungen"/></Typography>
			<Typography variant="subtitle1"><LText en="How well do you manage to focus? What causes you to break your focus?" de="Wie gut kannst Du Dich konzentrieren? Was lässt Dich den Fokus verlieren?"/></Typography>

			{props.isEmpty && <>
				<Box marginLeft="auto" marginRight="auto" width={300} marginTop={5} textAlign="center">
				<IllustrationEmpty/>
				</Box>
				<Box textAlign="center" maxWidth={420} marginLeft="auto" marginRight="auto" marginTop={-2}>
				<Typography variant="h6"><LText en="No data, yet" de="Noch keine Daten"/></Typography>
				<Typography variant="body1" style={{textAlign:"left"}}><LText en="Looks like you did not complete any session, yet. Check back later once you've used Focus Trainer for a while." de="Du hast noch keine Sitzung absolviert. Schau wieder vorbei, wenn Du Focus Trainer ein wenig genutzt hast."/></Typography>
				</Box>
			</>}

			{!props.isEmpty && <>
				<Card className={classes.card}>
					<CardHeader
						title={<LText en="Focus rate" de="Fokusrate"/>}
						subheader={<LText en="How consistently do you complete your focus sessions?" de="Wie konsequent hältst Du Deine Konzentrationsphasen durch?"/>}
					/>
					<CardContent>
						{/* <DualStreak
							numerator={7}
							denominator={10}
							streakCurrent={3}
							streakMax={17}
							title=""
							labelCurrent="Current average"
							labelStreak="Last 3 sessions successful. Record: 17 sessions."
						/> */}
						{props.streakWidget}
					</CardContent>
				</Card>

				<Card className={classes.card}>
					<CardHeader
						title={<LText en="Interruptions" de="Unterbrechnungen"/>}
						subheader={<LText en="What are common causes for aborted sessions?" de="Was sind häufige Gründe für unterbrochene Konzentrationsphasen?"/>}
					/>
					<CardContent>
						<List component="nav" dense={true}>
							{props.failedReasons.map(item => <ListItem key={item.reason}><ListItemText primary={'"' + item.reason + '" ('+item.count+'×)'} className={classes.listItemText}/></ListItem>)}
						</List>
						{props.failedReasons.length === 0 && <Typography variant="body1"><LText en="You haven't noted anything, yet." de="Du hast noch nichts notiert."/></Typography>}
					</CardContent>
				</Card>
			</>}
		</Container>
	</>
	;
}

