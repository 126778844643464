import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moodImage from '../assets/20160704-2004--DSC_2771.landscape.jpg';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	notificationButtons: {
		padding: theme.spacing(2),
		paddingBottom: 0,
		textAlign: "center",
	},
	notificationButton: {
		marginBottom: theme.spacing(2),
	},
	smallprint: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		textAlign: "center",
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	bullets: {
		fontSize: "1rem",
	},
	awaitingPermissionNotice: {
		marginBottom: theme.spacing(2),
	},
}));

const FormattedMessage = (props: {id: string, defaultMessage: string}) => {
	return <>{props.defaultMessage}</>;
}

interface Props {
	doEnableNotifications(): void,
	doPostponeNotifications(): void,
	awaitingNotificationPermission: boolean,
}

export default function OboardingNotifications(props: Props) {
	const { 
		doEnableNotifications,
		doPostponeNotifications,
		awaitingNotificationPermission,
	 } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><LText en="Background alerts" de="Timer-Erinnerungen"/></Typography>
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="body1" color="inherit">
						<LText
							en="Want to know when a session is complete even if Focus Trainer is currently in the background? Allow notifications and Focus Trainer will remind you of running background timers."
							de="Möchtest Du benachrichtigt werden, wenn Deine Konzentrationsphase abgeschlossen ist, selbst wenn Focus Trainer im Hintergrund ist? Erlaube Benachrichtigungen, damit Focus Trainer Dich an laufende Timer erinnern kann."
						/>
					</Typography>
					{/* <ul>
						<li className={classes.bullets}><FormattedMessage id="onboarding.notifications.bullet1" defaultMessage="Reminders to reflect on your progress"/></li>
						<li className={classes.bullets}><FormattedMessage id="onboarding.notifications.bullet2" defaultMessage="Reminders for your remaining objectives"/></li>
					</ul> */}
					{/* <Typography variant="body1" color="inherit">
						<FormattedMessage id="onboarding.notifications.description" defaultMessage="Please grant us the required permissions to do so."/>
					</Typography> */}
				</Box>
				{!awaitingNotificationPermission &&
					<React.Fragment>
					<Box className={classes.notificationButtons}>
						<Button onClick={doEnableNotifications} size="large" variant="contained" color="primary" className={classes.notificationButton}><LText en="Enable notifications" de="Benachrichtigungen erlauben"/></Button>
						<Button onClick={doPostponeNotifications} size="large" variant="text" className={classes.notificationButton}><LText en="No thanks" de="Nein, danke"/></Button>
					</Box>
				</React.Fragment>
				}
				{awaitingNotificationPermission &&
					<React.Fragment>
						<Alert severity="info" className={classes.awaitingPermissionNotice}>
							<LText
								en="You should now see a request from your device to allow notifications. Please accept it."
								de="Du solltest nun eine Anfrage Deines Gerätes sehen. Bitte erteile die Berechtigung."
							/><br/>
							{/* <br/>
							<FormattedMessage id="onboarding.notifications.awaiting2" defaultMessage="Once you have granted permission, you can continue clicking &quot;Done&quot; in the lower right corner."/><br/> */}
						</Alert>
					</React.Fragment>
				}
				<Box className={classes.smallprint}>
					<Typography variant="body2" color="inherit" gutterBottom>
						<LText en="You can disable notifications at any time." de="Du kannst Benachrichtigungen jederzeit deaktivieren."/>
					</Typography>
				</Box>

		</Container>
		</React.Fragment>
	);
}
