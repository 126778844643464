import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, TextField } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';

const useStyles = makeStyles(theme => ({
	textfield: {
		"& div": {
			paddingBottom: theme.spacing(2),
		},
		"& div::before": {
			borderBottom: "none",
		},
	},
	button: {
		position:"absolute",
		right: 0,
		top: theme.spacing(-1),
		zIndex: 9,
	},
}));

interface BraindumpItemProps {
	time: string,
	text: string,
}

export default function BraindumpItem(props: BraindumpItemProps) {
	const classes = useStyles();

	return (
		<>
			<Paper style={{marginBottom:16, padding: 8, paddingBottom: 0, backgroundColor:"#f4f4f4", position: "relative"}} elevation={0}>
				{/* <Checkbox
					style={{position:"absolute", right: 0, top: 0}}
					// checkedIcon={<UnarchiveIcon/>}
					// icon={<ArchiveIcon/>}
				/> */}
				<IconButton className={classes.button}>
					<ArchiveIcon fontSize="small"/>
				</IconButton>
				<TextField
					// label={<>
					// 	Braindump
					// 	<ButtonDialog>Yo</ButtonDialog>
					// 	</>}
					label={props.time}
					// helperText="Idea? Unexpected to-do? Write it down, bother later."
					// placeholder="Idea? "
					multiline 
					fullWidth
					// autoFocus
					value={props.text}
					// rowsMax={4}
					// style={{marginBottom:24}}
					className={classes.textfield}
				/>
			</Paper>
		</>
	);
}
