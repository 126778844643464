import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// const useStyles = makeStyles(theme => ({
// }));

export interface TimerProps {
	progress: number,
	minutes: number,
	seconds: number,
	running: boolean,
	color?: "secondary" | "primary" | "inherit",
	onStart(): void,
}

export default function Timer(props: TimerProps) {
	// const classes = useStyles();
	
	return <>

				<Box display="inline-block" position="relative">
					<CircularProgress
						variant="determinate"
						value={100 + props.progress}
						size={300}
						color={props.color || "secondary"}
						thickness={5}
					/>
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						paddingTop={1}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Box>
							{/* <Box textAlign="center"><Typography variant="h2">{props.time}</Typography></Box>  */}
							<Box textAlign="center">
								<Typography variant="h2">
									{props.running ? <>
										{props.minutes}:{props.seconds.toString().padStart(2, "0")}
									</> : <>
										{props.minutes}m
									</>}
								</Typography>
							</Box> 
							{/* <Box textAlign="center" marginTop={1}><Button startIcon={<NotesIcon/>} color="secondary">Braindump</Button></Box>
							<Box textAlign="center"><Button>Abort</Button></Box> */}
							{props.running && 
								<Box textAlign="center">
									<IconButton><DoneOutlinedIcon fontSize="large"/></IconButton>
									<IconButton><ClearOutlinedIcon fontSize="large"/></IconButton>
								</Box>
							}
							{!props.running && 
								<Box textAlign="center">
									{/* <Button variant="outlined" startIcon={<PlayArrowIcon fontSize="large"/>}>Start</Button> */}
									<IconButton onClick={props.onStart}><PlayArrowIcon 
									fontSize="large"
									/></IconButton>
								</Box>
							}
						</Box>
					</Box>
				</Box>
				{/* </Box> */}

							{/* <Box textAlign="center" marginTop={2}><Button startIcon={<NotesIcon/>} variant="outlined" color="secondary">Braindump</Button></Box>
							<Box textAlign="center"><Button>Abort</Button></Box> */}

	</>
	;
}

