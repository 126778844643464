import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
// import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import DialogNotched from './DialogNotched';
import Box from '@material-ui/core/Box';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import { Grid, IconButton, Link } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import TimeChip from './TimeChip';
import { FocusMode, Target } from '../App';
import LText from '../tools/LText';


const useStyles = makeStyles(theme => ({
	numberCell: {
		textAlign: "center",
	},
	numberButton: {
		fontSize: 24,
	},
	numberChip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	numberChipLock: {
		fontSize: 17,
		marginLeft: theme.spacing(1),
	},
}));


export interface SetTimeDialogProps {
	targets: Target[],
	initialTarget?: number,
	maxTarget: number,
	focusMode: FocusMode,
	onCancel(): void,
	onSetTime(minutes: number): void,
}

export default function SetTimeDialog(props: SetTimeDialogProps) {

	const [value, setValue] = useState("");
	const [showLockedNotice, setShowLockedNotice] = useState(false);

	// const suggestedReasons = Array(200).fill("").map(() => String(Math.round(Math.random() * 20000)));
	const theme = useTheme();
	// const { formatMessage, locale } = useIntl();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();

	const addLetter = (letter: string) => {
		const newValue = value + letter;
		const newTarget = parseInt(newValue);
		if (newTarget > props.maxTarget) {
			setShowLockedNotice(true);
		} else {
			setValue(newValue);
		}
	}

	const handleKeyUp: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		const key = e.key;
		const charCode = key.charCodeAt(0);
		// logger.debug("KeyUp", key, charCode, Number(key), e);
		if (charCode >= 48 && charCode <= 57) {
			addLetter(key);
		} else if (key === "Backspace") {
			setValue(value.slice(0, -1));
		}
	};

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={props.onCancel}
			aria-labelledby="responsive-dialog-title"
			onKeyUp={handleKeyUp}
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogContent >

				{/* <Typography variant="h4" gutterBottom paragraph>For how long do you want to focus?</Typography> */}
				{/* <Typography variant="h3" gutterBottom paragraph>Session length</Typography> */}
				<Typography variant="h4" style={{textAlign: "center", marginBottom: 32}}><LText en="Session length" de="Dauer"/></Typography>
				{/* <Typography variant="h5" gutterBottom paragraph>Session length</Typography> */}
				{/* <Typography variant="h6" gutterBottom paragraph>For how long do you want to focus?</Typography> */}
				
				<Typography variant="h3" color="primary" style={{textAlign: "center", paddingLeft: 48, marginBottom: 32}}>
					{value === "" ? <>
						<Box display="inline-block" minWidth={95} textAlign="right" color={theme.palette.text.disabled}>{props.initialTarget}m</Box>
					</> : <>
						<Box display="inline-block" minWidth={95} textAlign="right">{value}m</Box>
					</>}
					<IconButton disabled={value === ""} onClick={() => setValue(value.slice(0, -1))}><BackspaceOutlinedIcon/></IconButton>
				</Typography>

				<Box textAlign="center">
					{props.targets.map(v =>
							<TimeChip key={v.minutes} minutes={v.minutes} mode={props.focusMode} locked={v.locked} onClick={() => props.onSetTime(v.minutes)}/>
					)}
				</Box>

				<Grid container style={{maxWidth: 320, marginLeft: "auto", marginRight: "auto"}}>

					{/* <Grid item xs={12}>
						{[5,10,15,20,25].map(n => <>
							<Chip key={n} label="min" avatar={<Avatar>{n}</Avatar>} className={classes.numberChip}/>
						</>)}
					</Grid> */}

					{[1,2,3,4,5,6,7,8,9].map(n =>
						<Grid item xs={4} key={n} className={classes.numberCell}><Button size="large" className={classes.numberButton} onClick={() => addLetter(n.toString())}>{n}</Button></Grid>
					)}
					{/* <Grid item xs={4}>2</Grid>
					<Grid item xs={4}>3</Grid>
					<Grid item xs={4}>4</Grid>
					<Grid item xs={4}>5</Grid>
					<Grid item xs={4}>6</Grid>
					<Grid item xs={4}>7</Grid>
					<Grid item xs={4}>8</Grid>
					<Grid item xs={4}>9</Grid> */}
					<Grid item xs={12} className={classes.numberCell}><Button size="large" className={classes.numberButton} onClick={() => addLetter("0")}>0</Button></Grid>
				</Grid>

				{showLockedNotice &&
					<DialogNotched
						open={true}
						onClose={() => setShowLockedNotice(false)}
					>
						<DialogTitle>
							<LockIcon style={{position: "relative", top: 2}}/> <LText en="Maximum target time exeeded" de="Zu großes Ziel"/>
						</DialogTitle>
						<DialogContent>
							<Typography variant="body1" paragraph><LText
								en="The longest target time that you can currently choose is <strong>{maxTarget} minutes</strong>. Train with shorter targets to unlock longer times."
								de="Die längste Zielzeit, die Du derzeit wählen kannst, ist <strong>{maxTarget} Minuten</strong>. Übe mit kürzeren Zielen, um längere Zeiten freizuschalten."
								values={{maxTarget: props.maxTarget}}
								/>
							</Typography>
							<Typography variant="body1" paragraph><LText
								en="Remember: You don't need to stop focusing when the timer runs out. But to set longer times as a target from the beginning, you need to master the shorter times first."
								de="Denke daran: Du musst nicht aufhören, Dich zu konzentrieren, wenn der Timer abläuft. Aber um sich von Anfang an längere Zeiten als Ziel zu setzen, musst Du zuerst die kürzeren Zeiten beherrschen."
								/>
							</Typography>
							<Typography variant="body1"><Link href="#" target="_blank" rel="noopener"><LText en="Learn why." de="Erfahre mehr."/></Link></Typography>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setShowLockedNotice(false)} color="primary">
								<LText en="Close" de="Schließen"/>
							</Button>
							{/* <Button onClick={props.onClick} color="primary">
								Proceed
							</Button> */}
						</DialogActions>
					</DialogNotched>
				}

			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} color="primary" data-analytics-id="SetTimeDialog-cancel">
					<LText en="Cancel" de="Abbrechen"/>
				</Button>
				<Button onClick={() => props.onSetTime(parseInt(value))} color="secondary" autoFocus data-analytics-id="SetTimeDialog-save">
					<LText en="Set" de="Speichern"/>
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
