import { createMuiTheme } from '@material-ui/core/styles';

// https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=546E7A&secondary.color=c2185b

const purposeTheme = createMuiTheme({
	palette: {
		primary: {
			light: '#819ca9',
			main: '#546e7a',
			dark: '#29434e',
			contrastText: '#fafafa',
		},
		secondary: {
			light: '#fa5788',
			main: '#c2185b',
			dark: '#8c0032',
			contrastText: '#fff',
		},
		green: "#388e3c",
	},
});

export default purposeTheme;