import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import DialogNotched from './DialogNotched';
import backgroundImage from '../assets/stephen-leonardi-wPlzrculha8-unsplash.1920.jpg';
import Box from '@material-ui/core/Box';
import SimpleTimer from './SimpleTimer';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { DialogActions } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: '6px 16px',
	  },
	background: {
		backgroundImage: `url(${backgroundImage})`,
		backgroundSize: "cover",
	},
	content: {
		color: "white",
	},
	closeButton: {
		color: "white",
		borderColor: "white",
	}
}));


export interface LevelUpDialogProps {
	onClose(): void,
	lastMax: number,
	newMax: number,
}

export default function LevelUpDialog(props: LevelUpDialogProps) {

	// const suggestedReasons = Array(200).fill("").map(() => String(Math.round(Math.random() * 20000)));
	const theme = useTheme();
	// const { formatMessage, locale } = useIntl();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();


	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={props.onClose}
			aria-labelledby="responsive-dialog-title"
			// className={classes.background}
			// noBackground={true}
			backgroundImage={backgroundImage}
		>
			{/* <DialogTitle id="responsive-dialog-title">Well Done!</DialogTitle> */}
			<DialogContent className={classes.content}>

				<Typography variant="h5" gutterBottom paragraph><LockOpenIcon fontSize="large"/> Unlocked: {props.newMax} minutes</Typography>
				<Typography variant="body1" paragraph>You mastered focussing for {props.lastMax} minutes. Time for the next challenge! You can now select {props.newMax} minutes as target time.</Typography>
				{/* <Typography variant="h3" color="textSecondary">15 minutes</Typography> */}
				<Box textAlign="center" marginTop={5}>
					<SimpleTimer minutes={props.newMax} progress={0} onClick={props.onClose}/>
				</Box>


			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} data-analytics-id="LevelUpDialog-close" className={classes.closeButton} >Continue</Button>
			</DialogActions>
		</DialogNotched>
	);
}
