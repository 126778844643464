import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MobileStepper, Paper } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import LText from '../tools/LText';



const useStyles = makeStyles(theme => ({
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
		boxSizing: "border-box",
		paddingBottom: "env(safe-area-inset-bottom)",
	},
}));


interface NavigationOnboardingProps {
	steps: number,
	stepIndex: number,
	nextAvailable: boolean,
	onNext(): void,
	onBack(): void,
}

export default function NavigationOnboarding(props: NavigationOnboardingProps) {
	const classes = useStyles();

	return <>
			<Paper className={classes.footer} elevation={4}>
				<MobileStepper
					variant="dots"
					steps={props.steps}
					position="static"
					activeStep={0}
					nextButton={
						(props.stepIndex === props.steps-1) ?
							<Button disabled={!props.nextAvailable} onClick={props.onNext} variant="contained" color="secondary" style={{color: "white"}}><DoneIcon/>&nbsp;Done</Button>
						:
							<Button onClick={props.onNext} variant="contained" color="primary"><LText en="Continue" de="Weiter"/><KeyboardArrowRight /></Button>
					}
					backButton={
						<Button onClick={props.onBack} variant="text" color="default" disabled={props.stepIndex === 0}><KeyboardArrowLeft /><LText en="Back" de="Zurück"/></Button>
					}
				/>
			</Paper>
	</>;
}
