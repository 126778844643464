import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	later: {
		color: "white",
	}
}));

export interface SimpleTimerProps {
	progress: number,
	minutes: number,
	onClick(): void,
}

export default function SimpleTimer(props: SimpleTimerProps) {
	const classes = useStyles();
	
	return <>
				<Box position="relative" display="inline-flex">
					<CircularProgress
						variant="determinate"
						value={100 + props.progress}
						size={250}
						color="secondary"
						thickness={5}
					/>
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						paddingTop={1}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Box>
							{/* <Box textAlign="center"><Typography variant="h2">{props.time}</Typography></Box>  */}
							<Box textAlign="center" paddingBottom={1}>
								<Typography variant="h3">
									{props.minutes}m
								</Typography>
							</Box> 
							{/* <Box textAlign="center" marginTop={1}><Button startIcon={<NotesIcon/>} color="secondary">Braindump</Button></Box>
							<Box textAlign="center"><Button>Abort</Button></Box> */}
								<Box textAlign="center">
									{/* <IconButton><PlayArrowIcon
									// fontSize="large"
									/></IconButton> */}
									{/* <Button variant="outlined" color="primary">Accept</Button> */}
									<Button variant="contained" color="primary" onClick={props.onClick}>Continue</Button>
									{/* <Button className={classes.later}>Later</Button> */}
								</Box>
						</Box>
					</Box>
				</Box>
				
	</>
	;
}

