import React, { useContext, useEffect, useState } from 'react';
import purposeTheme from '../src/theme-bluegrey-red.js';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import TimerViewV2 from './components/TimerViewV2';
import NavigationBottom from './components/NavigationBottom';
// import TimerViewV2Joyride from './components/TimerViewV2Joyride';
import TimerViewController from './controllers/TimerViewController';
import FocusSessionsContext from './contexts/FocusSessionsContext';
import logger from './services/logger';
import InsightsViewController from './controllers/InsightsViewController';
import BraindumpsViewController from './controllers/BraindumpsViewController';
import LevelUpDialog from './components/LevelUpDialog';
import SetTimeDialog from './components/SetTimeDialog';
import { Storage } from '@capacitor/storage';
import LoadingView from './components/LoadingView';
import OnboardingViewController from './controllers/OnboardingViewController';
import { parseJSON } from 'date-fns';
import TimerViewV2Joyride from './components/TimerViewV2Joyride';
import TimerRunningJoyride from './components/TimerRunningJoyride';
import JoyrideController from './controllers/JoyrideController';
import DebugViewController from './controllers/DebugViewController';
import HelpView from './components/HelpView';
import RemoteConfigContext from './contexts/RemoteConfigContext';


export enum FocusMode {
	Focus = "focus",
	Relax = "relax",
}

export interface Target {
	minutes: number,
	locked: boolean,
}

enum Views {
	Loading = "loading",
	Onboarding = "onboarding",
	TimerStart = "timer",
	TimerRunning = "timer-running",
	Insights = "insights",
	Braindumps = "braindumps",
	Debug = "debug",
	Help = "help",
}

export interface TimerState {
	startDate: Date,
	targetMinutes: number,
	focusMode: FocusMode,
	braindumpIds: string[],
}

function App() {
	const [view, setView] = useState(Views.Loading);
	const [timerState, setTimerState] = useState<TimerState|undefined>(undefined);
	const [focusMode, setFocusMode] = useState(FocusMode.Focus);
	const [targetSecondsFocus, _setTargetSecondsFocus] = useState(300);
	const [targetSecondsRelax, _setTargetSecondsRelax] = useState(300);
	const [showLevelUp, setShowLevelUp] = useState<{last: number, new: number}|false>(
		// {last:15, new:25}
		false
	);
	// const targets = useRef([5, 10, 15, 25, 45, 60, 75, 90].map(t => ({minutes: t, locked: t > 15 ? true : false})));
	const [targets, setTargets] = useState<Target[]>([5, 10, 15, 25, 45, 60, 75, 90].map(t => ({minutes: t, locked: t > 15 ? true : false})));
	const [lastMaxTarget, setLastMaxTarget] = useState(0);
	// const lastMaxTarget = useRef(0);
	const [showKeypad, setShowKeypad] = useState(false);

	const focusSessions = useContext(FocusSessionsContext);
	const remoteConfig = useContext(RemoteConfigContext);

	const findMaxTarget = () => {
		let max = 0;
		targets.forEach(t => {
			if (t.minutes > max && !t.locked)
				max = t.minutes;
		});
		return max;
	}

	const currentMax = findMaxTarget();

	useEffect(() => {
		logger.debug("Checking level up... ", lastMaxTarget, currentMax);
		if (lastMaxTarget > 0) {		
			if (currentMax > lastMaxTarget) {
				logger.info("Level up! "+lastMaxTarget+" -> "+currentMax);
				setShowLevelUp({last: lastMaxTarget, new: currentMax});
				setLastMaxTarget(currentMax);
				Storage.set({key: "LastMaxTarget", value: currentMax.toString()});
			}
		}
	}, [targets, currentMax, lastMaxTarget]);

	useEffect(() => {
		if (lastMaxTarget === 0) {
			Storage.get({key: "LastMaxTarget"}).then(value => {
				if (value.value) {
					setLastMaxTarget(parseInt(value.value));
				} else {
					setLastMaxTarget(15);
				}
			});
		}
	}, [lastMaxTarget]);

	function setTargetSeconds(seconds: number) {
		Storage.set({key: "TargetSeconds", value: JSON.stringify({
				focus: focusMode === FocusMode.Focus ? seconds : targetSecondsFocus,
				relax: focusMode === FocusMode.Relax ? seconds : targetSecondsRelax,
			})
		});
		if (focusMode === FocusMode.Focus)
			_setTargetSecondsFocus(seconds);
		else
			_setTargetSecondsRelax(seconds);
	}

	useEffect(() => {
		Storage.get({key: "TargetSeconds"}).then(value => {
			if (value.value) {
				const t = JSON.parse(value.value);
				logger.info("Restored target times", t);
				_setTargetSecondsFocus(t.focus);
				_setTargetSecondsRelax(t.relax);
			}
			Storage.get({key: "TimerState"}).then(value => {
				if (value.value) {
					const j = JSON.parse(value.value);
					const ts: TimerState = {
						startDate: parseJSON(j.startDate),
						targetMinutes: j.targetMinutes,
						focusMode: j.focusMode,
						braindumpIds: j.braindumpIds,
					}
					logger.info("Loaded timer state", ts);
					setFocusMode(ts.focusMode);
					setTimerState(ts);
					setView(Views.TimerRunning);
				} else {
					setView(Views.Onboarding);
				}
			});
		});
	}, []);

	useEffect(() => {
		logger.debug("Checking target lock status...");
		// const targets = [5, 10, 15, 25, 45, 60, 75, 90];
		let lastTargetMastered = 1;
		let targetChanged = false;
		const newTargets = targets.map(t => Object.assign({}, t));
		newTargets.forEach(target => {
			if (lastTargetMastered) {
				if (target.locked) {
					logger.debug("Unlocking target "+target.minutes+"m because last target was mastered");
					target.locked = false;
					targetChanged = true;
				}
				let maxSuccess = 0;
				let successCounter = 0;
				focusSessions.sessions.forEach(session => {
					if (session.success && session.length >= target.minutes * 60)
						successCounter++;
					else if (!session.success)
						successCounter = 0;
					if (successCounter > maxSuccess)
						maxSuccess = successCounter;
				});
				logger.debug("Target status", target, maxSuccess);
				if (maxSuccess < 2)
					lastTargetMastered = 0;
			}
		});
		if (targetChanged) {
			setTargets(newTargets);
		}
	}, [focusSessions.sessions, targets]);


	// const _focusTimesStart = [
	// 	{minutes: 5, locked: false},
	// 	{minutes: 10, locked: false},
	// 	{minutes: 15, locked: false},
	// 	{minutes: 25, locked: true},
	// 	{minutes: 45, locked: true},
	// 	// {minutes: 60, locked: true},
	// ];

	const focusTimesStart = targets.slice(0, 5);

	const targetMinutes = Math.floor((focusMode === FocusMode.Focus ? targetSecondsFocus : targetSecondsRelax) / 60);

	const handleFocusSessionSuccess = (elapsed: number) => {
		logger.info("Session completed successfully. Focus time: "+elapsed);
		setFocusMode(focusMode === FocusMode.Focus ? FocusMode.Relax : FocusMode.Focus);
		setView(Views.TimerStart);
		clearTimerState();
	}

	useEffect(() => {
		if (timerState !== undefined) {
			const ts_string = JSON.stringify(timerState);
			logger.debug("Persisting timer state:"+ts_string);
			Storage.set({key: "TimerState", value: ts_string});
		}
	}, [timerState]);

	const startTimer = () => {
		const ts: TimerState = {
			startDate: new Date(),
			targetMinutes: targetMinutes,
			focusMode: focusMode,
			braindumpIds: [],
		}
		setTimerState(ts);
		setView(Views.TimerRunning);
	}

	const clearTimerState = () => {
		logger.debug("Clearing timer state");
		Storage.remove({key: "TimerState"});
		setTimerState(undefined);
	}

	const handleChangeSessionBraindumpIds = (ids: string[]) => setTimerState(old => Object.assign({}, old, {
		braindumpIds: ids,
	}));

	return (
		<MuiThemeProvider theme={purposeTheme}>
			<CssBaseline />

			{view === Views.Loading && <>
				<LoadingView/>
			</>}

			{view === Views.Onboarding && <>
				<OnboardingViewController onDone={() => setView(Views.TimerStart)}/>
			</>}

			{view === Views.TimerStart && <>
				<TimerViewV2
					progress={0}
					braindumping={false}
					running={false}
					minutes={targetMinutes}
					seconds={0}
					disableUnderline={true}
					editing={false}
					onStart={startTimer}
					onSetCustomTime={() => {setShowKeypad(true)}}
					hideDumps={true}
					mode={focusMode}
					onFocusModeChange={(mode) => setFocusMode(mode)}
					focusTimes={focusTimesStart}
					onSetTime={(minutes) => {setTargetSeconds(minutes*60)}}
				/>
				{showLevelUp && 
					<LevelUpDialog
						onClose={() => setShowLevelUp(false)}
						lastMax={showLevelUp.last}
						newMax={showLevelUp.new}
					/>
				}
				{showKeypad &&
					<SetTimeDialog
						targets={targets}
						initialTarget={targetMinutes}
						maxTarget={currentMax}
						focusMode={focusMode}
						onSetTime={(minutes: number) => {
							logger.info("New target: "+minutes);
							setTargetSeconds(minutes*60);
							setShowKeypad(false);
						}}
						onCancel={() => setShowKeypad(false)}
					/>
				}
				<JoyrideController id="TimerStart" ride={<TimerViewV2Joyride/>}/>
			</>}
			{view === Views.TimerRunning && timerState && <>
				<TimerViewController
					startDate={timerState.startDate}
					targetSeconds={timerState.targetMinutes * 60}
					braindumpIds={timerState.braindumpIds}
					mode={focusMode}
					onCancel={() => {
						setView(Views.TimerStart);
						clearTimerState();
					}}
					onSucceeded={handleFocusSessionSuccess}
					onInterrupted={() => {
						setView(Views.TimerStart);
						clearTimerState();
					}}
					onChangeBraindumpIds={handleChangeSessionBraindumpIds}
				/>
				<JoyrideController id="TimerRunning" ride={<TimerRunningJoyride/>}/>
			</>}
			{view === Views.Braindumps && <>
				<BraindumpsViewController/>
			</>}
			{view === Views.Insights && <>
				<InsightsViewController/>
			</>}
			{view === Views.Debug && <>
				<DebugViewController/>
			</>}

			{view === Views.Help && <>
				<HelpView
					showPhoneNumber={remoteConfig.helpShowPhone}
				/>
			</>}

			{view !== Views.TimerRunning && view !== Views.Onboarding && view !== Views.Loading && <>
				<NavigationBottom
					selected={view}
					onNavigateTimer={() => setView(Views.TimerStart)}
					onNavigateBraindumps={() => setView(Views.Braindumps)}
					onNavigateInsights={() => setView(Views.Insights)}
					onNavigateAccount={() => {}}
					onNavigateDebug={() => setView(Views.Debug)}
					onNavigateHelp={() => setView(Views.Help)}
				/>
			</>}
		</MuiThemeProvider>
		);
}

export default App;
