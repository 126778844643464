import React, { useState } from 'react';
import { Box, Button, Fade, TextField } from '@material-ui/core';
import LText from '../tools/LText';

export interface BraindumpInputProps {
	onSave(value: string): void,
}

export default function BraindumpInput(props: BraindumpInputProps) {
	const [value, setValue] = useState("");

	const handleSave = () => {
		props.onSave(value);
		setValue("");
	}

	return (
		<>
			<Box>
				<TextField
				// label={<>
				// 	Braindump
				// 	<ButtonDialog>Yo</ButtonDialog>
				// 	</>}
				label={<LText en="Braindump" de="Ablage"/>}
				helperText={<LText en="Idea? Unexpected to-do? Write it down, bother later." de="Idee? Aufgabe? Jetzt aufschreiben, später kümmern."/>}
				// placeholder="Idea? "

				multiline 
				fullWidth
				value={value}
				onChange={(e) => setValue(e.target.value)}
				// rowsMax={4}
				/>
				<Fade in={value !== ""}>
					<Box textAlign="right" marginTop={2} marginBottom={2}>
						<Button variant="text" onClick={() => setValue("")}><LText en="Cancel" de="Abbrechen"/></Button>
						<Button variant="contained" color="secondary" onClick={handleSave}><LText en="Save" de="Speichern"/></Button>
						{/* <Button variant="outlined" color="secondary">Save</Button> */}
					</Box>
				</Fade>
			</Box>
		</>
	);
}
