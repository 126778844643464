import React, { useState, useEffect } from 'react';
import logger from '../services/logger';
import FocusSessionsContext, { FocusSession } from './FocusSessionsContext';
import { Storage } from '@capacitor/storage';
import { parseJSON } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

interface Props {
	children: React.ReactNode,
}
export default function FocusSessionsProvider(props: Props) {

	const [ready, setReady] = useState<boolean>(true);
	const [sessions, setSessions] = useState<FocusSession[]>([]);

	useEffect(() => {
		Storage.get({ key: 'FocusSessions' }).then(value => {
			logger.debug("Session data:"+value.value);
			if (value.value)
				setSessions(JSON.parse(value.value).map((item:any) => {
					const newItem: FocusSession = {
						id: item.id,
						date: parseJSON(item.date),
						target: item.target,
						length: item.length,
						success: item.success,
						failedReason: item.failedReason,
					};
					return newItem;
				}));
		});
		
		Storage.keys().then(value => logger.debug('Storage keys', value));
	}, []);


	const contextValue = {
		ready: () => ready,
		create: (date: Date, target: number, length: number, success: boolean, failedReason?: string) => {
			const newItem: FocusSession = {
				id: uuidv4(),
				date: date,
				target: target,
				length: length,
				success: success,
				failedReason: failedReason || "",
			};
			setSessions(old => {
				const updatedSessions = [...sessions, newItem];
				logger.debug("Updating FocusSessions storage");
				Storage.set({key: "FocusSessions", value: JSON.stringify(updatedSessions)});
				return updatedSessions;
			});
			return newItem;
		},
		sessions: sessions,
	}

	logger.debug("Updated FocusSessionsProvider", sessions);

	return (
		<FocusSessionsContext.Provider value={contextValue}>
			{props.children}
		</FocusSessionsContext.Provider>
	);
}
