import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Collapse, Theme } from '@material-ui/core';
import BraindumpInput from './BraindumpInput';
import BraindumpCheckbox from './BraindumpCheckbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Braindump } from '../contexts/BraindumpsContext';
import LText from '../tools/LText';

const useStyles = makeStyles<Theme, styleProps>(theme => ({
	chevron: {
		transform: (props) => props.showItems ? "rotate(90deg)" : "rotate(0deg)",
		transition: "transform 0.5s",
	}
}));

interface styleProps {
	showItems: boolean,
}

export interface BraindumperProps {
	onChange?(items: Braindump[]): void,
	braindumps?: Braindump[],
	onCreateBraindump?(content: string): void,
	onChangeBraindump?(item: Braindump): void,
	onDeleteBraindump?(id: string): void,
}

export default function Braindumper(props: BraindumperProps) {
	// const [items, setItems] = useState<Braindump[]>([]);
	const [showItems, setShowItems] = useState(false);
	const classes = useStyles({showItems: showItems});

	// const addItem = (content: string) => {
	// 	setItems(oldItems => {
	// 		const newItems = [...oldItems, {
	// 			date: new Date(),
	// 			content: content,
	// 			done: false,
	// 		}];
	// 		if (props.onChange)
	// 			props.onChange(newItems);
	// 		return newItems;
	// 	});
	// }

	const toggleShowItems = () => {
		setShowItems(old => !old);
	}

	// const handleItemChange = (item: Braindump, checked: boolean) => {
	// 	setItems(oldItems => 
	// 		oldItems.map(i =>
	// 			Object.assign({}, i, {
	// 				done: isEqual(i.date, item.date) ? checked : i.done,
	// 			})
	// 		)
	// 	);
	// }

	return (
		<>
			<BraindumpInput onSave={item => props.onCreateBraindump?.(item)}/>
			<Collapse in={props.braindumps && props.braindumps.length > 0}>
				<Box>
				{/*  */}
					<Button startIcon={<ChevronRightIcon className={classes.chevron}/>} onClick={toggleShowItems}><LText en="{count, plural, one {# thought} other {# thoughts}} dumped" de="{count, plural, one {# Gedanke} other {# Gedanken}} abgelegt" values={{count: props.braindumps?.length}}/></Button>
					<Collapse in={showItems}>
						<Box>
							{props.braindumps?.map((item, index) =>
								<BraindumpCheckbox
									key={index}
									braindump={item}
									onChangeDone={(checked) => props.onChangeBraindump?.(Object.assign({}, item, {done: checked}))}
									onChangeContent={(content) => props.onChangeBraindump?.(Object.assign({}, item, {content: content}))}
									onDelete={() => props.onDeleteBraindump?.(item.id)}
								/>	
							)}
						</Box>
					</Collapse>
				</Box>
			</Collapse>
		</>
	);
}
