import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import { Container, Grid } from '@material-ui/core';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(8),
	}
}));

export interface DebugViewProps {
	values: Record<string, string>,
}

export default function DebugView(props: DebugViewProps) {
	const classes = useStyles();

	return (
		<Container maxWidth="xs" className={classes.container}>
			<Typography variant="h4" gutterBottom paragraph>Technical information</Typography>

			<Grid container spacing={4}>
				<Grid item xs={12}>
					{Object.entries(props.values).map(([label, value]) =>
						<Typography key={label} variant="body1">{label}: {value}</Typography>
					)}
				</Grid>
			</Grid>
		</Container>
	);
}
