import React, { useContext, useMemo } from 'react';
import { Link } from '@material-ui/core';
import IntlMessageFormat from 'intl-messageformat';
import LangContext from '../contexts/LangContext';

const commonMessageValues = {
	br: <br/>,
	b: (m:any) => <strong>{m}</strong>,
	strong: (m:any) => <strong>{m}</strong>,
	"contact-en": (m:any) => <Link target="_blank" rel="noopener" href="https://www.focustrainer.app/en/contact/">{m}</Link>,
	"contact-de": (m:any) => <Link target="_blank" rel="noopener" href="https://www.focustrainer.app/de/kontakt/">{m}</Link>,
}


interface LTextProps {
	de: string,
	en: string,
	values?: Record<string, string | number | boolean | null | undefined | Date | JSX.Element>,
}

export default function LText(props: LTextProps) {
	const lang = useContext(LangContext);

	const message = lang.lang === "de" ? props.de : props.en;

	const msg = new IntlMessageFormat(message, lang.lang);


	// https://formatjs.io/docs/intl-messageformat/

	return <>{msg.format(Object.assign({}, commonMessageValues, props.values))}</>;
}

export function useLString() {
	const lang = useContext(LangContext);

	const lstr = useMemo(() => (en: string, de:string, values?: Record<string, string | number | boolean | Date>): string => {
		const message = lang.lang === "de" ? de : en;
		const msg = new IntlMessageFormat(message, lang.lang);
		// const v = Object.assign({}, values);
		const r = msg.format(values);
		if (typeof r === "string")
			return r;
		else if (Array.isArray(r))
			return r.join('');
		else
			throw new Error("Unexpected type " + typeof r);
	}, [lang.lang]);
	
	return lstr;
}
